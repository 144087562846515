import aIBrain from '../img/RHLSbanner_AI.png';
import aITablet from '../img/RHLSbanner_AITablet.png';
import cloudOpenStack from '../img/RHLSbanner_CloudOpenStack.png';
import controlBar from '../img/RHLSbanner_ControlBar.png';
import digitalBadge from '../img/RHLSbanner_DigitalBadge.png';
import heroBanner from '../img/hero-banner.png';
import kubernetesGraphic from '../img/RHLSbanner_Kubernetes.png';
import bannerExact from '../img/banner-exact.png';
import ITOptimization from '../img/RHLSbanner_ITOptimization.png';
import bannerRemoteExams from '../img/RHLSbanner_RemoteExams.png';
import womanClouds from '../img/RHLSbanner_WomanClouds.png';
import retakesNewsFlash from '../img/RHLSbanner_ExamRetakesNewsflash.png';
import personHybridCloud from '../img/RHLSbanner_hybridCloud.png';
import dashboardFeedback from '../img/feedback-dark.jpg';
import webinar from '../img/webinar-hero.png';
import newsFlash8 from '../img/newsflash03.png';
import RHLSNewsletter from '../img/RHLSbanner_Newsletter.png';
import tourDemo from '../img/RHLSbanner_Tour.png';
import RHLC200k from '../img/RHLSbanner_RHLC200k.png';
import RHCPotY2025 from '../img/RHLSbanner_CPotY2025.png';
import techOverviews from '../img/RHLSbanner_TechOverviews.png';
import whiteboardBanner from '../img/RHLSbanner_whiteboarding.png';
import womanLaptop from '../img/RHLSbanner_WomanLaptop.png';
import womanComputer from '../img/RHLSbanner_PersonAtComputer.png';

// DO NOT import animation json files unless they are really being used.
// By importing them the app bundle size is increased significantly and the loading times will also increase
// import earlyAccess from '../img/ea-animation.json';
// import skillsPath from '../img/skills-paths-edited.json';
// import eigthtTopTips from '../img/eightExamTips.json';
// import premium from '../img/premium_hero_animation.json';

import { APP_BASE } from '../config/constants';

const imgs = {
  aIBrain,
  aITablet,
  cloudOpenStack,
  controlBar,
  heroBanner,
  kubernetesGraphic,
  bannerExact,
  ITOptimization,
  bannerRemoteExams,
  digitalBadge,
  retakesNewsFlash,
  personHybridCloud,
  dashboardFeedback,
  webinar,
  newsFlash8,
  womanClouds,
  RHCPotY2025,
  RHLC200k,
  RHLSNewsletter,
  techOverviews,
  tourDemo,
  whiteboardBanner,
  womanLaptop,
  womanComputer,
};

export default [
  {
    title: `New course available`,
    titleColor: `black`,
    description: `Securing Kubernetes Clusters with Red Hat Advanced Cluster Security (DO430) covers the installation, configuration and usage of the product to secure Kubernetes clusters as per industry standards.`,
    descriptionColor: `black`,
    imageUrl: imgs.kubernetesGraphic,
    imageAlt: 'graphic of kubernetes concept',
    actionType: 'openLink',
    actionText: 'Visit the Catalog',
    actionUrl: `${APP_BASE}/courses/do430-4.6/pages/pr01`,
    openActionInNewTab: false,
    displayForSubscriptions: [
      'ls520',
      'ls220',
      'ls120',
      'ls130c',
      'ls810',
      'ls999',
    ],
  },
  {
    title: `Control bar now available`,
    titleColor: `white`,
    description: `The new Control Bar brings all of your course functions into a single bar, giving you a larger focus on the training content. Visit any course to engage in the new experience!`,
    descriptionColor: `white`,
    imageUrl: imgs.controlBar,
    imageAlt: 'Woman working with User Interface',
    actionType: 'openLink',
    actionText: 'Visit the Catalog',
    actionUrl: `${APP_BASE}/catalog`,
    openActionInNewTab: false,
  },
  {
    title: `Lessons are available`,
    titleColor: `black`,
    description: `The Red Hat Learning Subscription now has a new delivery format called Lessons. These concise, self-paced offerings focus on a single topic and objective, incorporating hands-on lab activities to reinforce learning. Visit the Catalog to search for available Lessons.`,
    descriptionColor: `black`,
    imageUrl: imgs.womanComputer,
    imageAlt: 'Person in front of a computer',
    actionType: 'openLink',
    actionText: 'Visit the Catalog',
    actionUrl: `${APP_BASE}/catalog`,
    openActionInNewTab: false,
    displayForSubscriptions: [
      'ls520',
      'ls220',
      'ls120',
      'ls130c',
      'ls810',
      'ls999',
    ],
  },
  {
    title: `Welcome to Red Hat Learning Subscription Standard`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.tourDemo,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_41isrruz',
    displayForSubscriptions: ['ls220'],
  },
  {
    title: `Welcome to Red Hat Learning Subscription Basic`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.tourDemo,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_h07quaro',
    displayForSubscriptions: ['ls120', 'ls130c', 'ls810'],
  },
  {
    title: `Welcome to Red Hat Learning Subscription Developer`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.tourDemo,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_h2hmxml5',
    displayForSubscriptions: ['ls300'],
  },
  {
    title: `Welcome to Red Hat Learning Subscription Premium`,
    titleColor: `red`,
    description: `Red Hat Learning Subscription is designed to effectively keep you up to speed with evolving Red Hat technologies.`,
    descriptionColor: `black`,
    imageUrl: imgs.tourDemo,
    imageAlt: 'Red Hat Learning Subscription 4.0 slide',
    actionType: 'showVideo',
    actionText: 'View The Tour',
    actionUrl:
      'https://cdnapisec.kaltura.com/p/2032581/embedPlaykitJs/uiconf_id/52299142?iframeembed=true&entry_id=1_7nb59zuy',
    displayForSubscriptions: ['ls520', 'ls105', 'ls000', 'ls101', 'ls999'],
  },
  {
    title: `Extra! Extra!`,
    titleColor: `white`,
    description: `Read all about it! Subscribe to the Red Hat Learning Subscription newsletter to receive all of the latest updates available to you. This includes course and exam releases, new features, and important Red Hat Training announcements.`,
    descriptionColor: `black`,
    imageUrl: imgs.RHLSNewsletter,
    imageAlt: 'Newspaper',
    actionType: 'openLink',
    actionText: 'Subscribe',
    actionUrl: `https://www.redhat.com/en/engage/red-hat-learning-subscription-newsletter-opt-in-20240522`,
    openActionInNewTab: true,
  },
];
