import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import {
  inject,
  PropTypes as MobXPropTypes,
  observer,
  Provider,
} from 'mobx-react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from 'react-bootstrap';
import { Alert } from '@patternfly/react-core';
import { withTranslation } from 'react-i18next';
import LabControls from './LabControls';
import AutostopTimer from './LabControls/AutostopTimer';
import LabWebApps from './LabControls/LabWebAppPanel';
import DefaultLabComponent from './DefaultLabComponent';
import OpenstackLabComponent from './OpenstackLabComponent';
import OCPSharedLabComponent from './OCPSharedLabComponent';
import TowerLabComponent from './TowerLabComponent';
import PTLLabComponent from './PTLLabComponent';
import './styles.scss';

import {
  LAB_DATA_POLLING_SECONDS,
  LAB_AUTOSHUTDOWN_POLLING_SECONDS,
} from '../../config/constants';

import LabEnvironmentStore from './store';

@inject('userStore', 'uiStore')
@withTranslation()
@observer
class LabEnvironment extends Component {
  componentMap = {
    shared_ocp: OCPSharedLabComponent,
    tower: TowerLabComponent,
    openstack: OpenstackLabComponent,
    ptl: PTLLabComponent,
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    courseSlug: PropTypes.string.isRequired,
    labDefinition: MobXPropTypes.observableObject.isRequired,
    uiStore: MobXPropTypes.observableObject.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    currentEnrollment: PropTypes.object,
    targetEnrollment: PropTypes.object,
    enrollmentUuid: PropTypes.string,
  };

  static defaultProps = {
    currentEnrollment: {},
    targetEnrollment: {},
    enrollmentUuid: '',
  };

  constructor(props) {
    super(props);
    this.store = new LabEnvironmentStore(this.props.userStore);
    this.store.currentEnrollment = this.props.currentEnrollment;
    this.store.targetEnrollment = this.props.targetEnrollment;
    this.store.labDefinition = this.props.labDefinition;
    // set courseSlug last, as it triggers the action.
    this.store.courseSlug = this.props.courseSlug;

    // force use :(
    console.warn(props.userStore);
  }

  componentDidMount() {
    this.store.pollIntervalSecs = LAB_DATA_POLLING_SECONDS;

    // checking lab hours should not apply for VT lab start
    if (
      !(this.props.currentEnrollment.uuid || this.props.targetEnrollment.uuid)
    ) {
      this.props.userStore.setEnablementsQuotasConfigurations(
        this.props.courseSlug,
      );

      // schedule to get check quota usage if its required
      if (this.props.userStore.enforceLabLimit) {
        this.checkLabQuota();
      }

      this.store.setInhibitLabInfo();
    }

    if (this.props.userStore.isLabPowerUser) {
      this.store.getOspHeatTemplates();
    }
  }

  componentDidUpdate() {
    if (this.props.courseSlug !== this.store.courseSlug) {
      this.store.resetLabInformation();
      this.updateStore();
    }

    // clear labHoursWatchdog schedule, user used lab usage limit and lab is stopped
    if (
      !this.store.hasLabHours &&
      this.store.userLab?.desired_state !== 'running'
    ) {
      if (this.labHoursWatchdog) clearTimeout(this.labHoursWatchdog);
    }

    if (this.props.uiStore.currentLanguage !== this.store.currentLanguage) {
      this.store.currentLanguage = this.props.uiStore.currentLanguage;
      this.store.resetLabInformation();
      this.store.getLabInformation(this.props.uiStore.currentLanguage);
    } else if (this.store.canFetchLabInformation) {
      this.store.getLabInformation(this.props.uiStore.currentLanguage);
    }
  }

  componentWillUnmount() {
    this.store.pollIntervalSecs = 0;
    if (this.labHoursWatchdog) clearTimeout(this.labHoursWatchdog);
  }

  getComponents() {
    const ul = this.store.userLab;

    if (!ul.doc_id || !this.store.hasLabHours) {
      return null;
    }

    const elems = ul.components.map((comp) => {
      const elemComp = this.componentMap[comp.driver] || DefaultLabComponent;
      const props = {
        action: this.store.doCommand,
        labStore: this.store,
        labComponent: comp,
        key: comp.slug,
      };
      return React.createElement(elemComp, props);
    });
    return elems;
  }

  getLabUsageAlertInfo() {
    const labUsageHoursLeft = this.props.userStore.labHoursLeft;
    const roundLabUsageHoursLeft = Math.round(labUsageHoursLeft);
    const { t } = this.props;

    let labUsageAlertHoursList =
      this.props.userStore.enablementsConfigurations?.lab_usage_alert_hours ||
      [];
    labUsageAlertHoursList = labUsageAlertHoursList.sort((a, b) => {
      return a - b;
    });

    if (labUsageHoursLeft > (last(labUsageAlertHoursList) || 20)) return null;
    if (labUsageHoursLeft >= 10) {
      return {
        title: (
          <>
            {t('Lab hours remaining:')} {roundLabUsageHoursLeft}
          </>
        ),
        variant: 'info',
        description: (
          <>
            {t('You have:')} {roundLabUsageHoursLeft}{' '}
            {t(
              'lab hours remaining. Keep up the momentum to reach your goals. Plan your time effectively to maximize your progress.',
            )}
          </>
        ),
      };
    }

    if (labUsageHoursLeft >= 1) {
      return {
        title: (
          <>
            {t('Lab hours remaining:')} {roundLabUsageHoursLeft}
          </>
        ),
        variant: 'warning',
        description: (
          <>
            {t('You have:')} {roundLabUsageHoursLeft}{' '}
            {t(
              'study hours remaining. Stay focused and prioritize your most important tasks to make the most of your time.',
            )}
          </>
        ),
      };
    }

    if (labUsageHoursLeft > 0) {
      return {
        title: <>{t('Lab hours remaining: less than one')}</>,
        variant: 'warning',
        description: (
          <>
            {t(
              'You have less than one lab hour remaining. Wrap up your work and plan your next steps to stay on track.',
            )}
          </>
        ),
      };
    }

    return {
      title: <>{t('Lab hours exhausted')}</>,
      variant: 'warning',
      description: (
        <>
          {t(
            'You have used all your allocated lab hours. Well done on reaching this milestone in your learning journey - your dedication is truly commendable! As you progress, no further labs can be started during this subscription period. Thank you for your hard work, and stay motivated for the next steps ahead.',
          )}
        </>
      ),
    };
  }

  usageAlert() {
    if (
      !this.props.userStore.enforceLabLimit ||
      this.props.currentEnrollment.uuid ||
      this.props.targetEnrollment.uuid
    ) {
      return null;
    }

    const usageAlertInfo = this.getLabUsageAlertInfo();
    return usageAlertInfo ? (
      <Alert
        variant={usageAlertInfo.variant}
        className="lab_alert"
        isInline
        title={usageAlertInfo.title}
      >
        <p>{usageAlertInfo.description}</p>
      </Alert>
    ) : null;
  }

  checkLabQuota() {
    const { t } = this.props;
    this.labHoursWatchdog = setTimeout(() => {
      // pull only lab usage quota
      this.props.userStore
        .getQuotasUsage(this.props.enrollmentUuid, 'lab_hours')
        .then(() => {
          if (
            !this.props.userStore.hasLabHours &&
            this.store.userLab?.state === 'running' &&
            // eslint-disable-next-line
            this.store.userLab?.desired_state !== 'stopped'
          ) {
            this.store.doCommand('app_stop');
            this.props.userStore.getEnablements(
              !this.props.userStore.isAlaCarte,
            );
            // clear labHoursWatchdog schedule once the quota reached
            if (this.labHoursWatchdog) clearTimeout(this.labHoursWatchdog);

            this.store.createNewModal(
              {
                title: t(
                  'Your labs have shut down. You have used all of your available lab hours.',
                ),
                dismissText: t('Ok'),
              },
              true,
            );
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.checkLabQuota();
        });
    }, LAB_AUTOSHUTDOWN_POLLING_SECONDS * 1000);
  }

  updateStore() {
    this.store.currentEnrollment = this.props.currentEnrollment;
    this.store.targetEnrollment = this.props.targetEnrollment;
    this.store.labDefinition = this.props.labDefinition;
    this.store.courseSlug = this.props.courseSlug;
    this.store.lastRequestedCommand = null;
    this.store.userLab = {};
    this.store.fetchUserLab();
    this.store.setInhibitLabInfo();
  }

  render() {
    const comps = this.getComponents();

    return (
      <Provider labStore={this.store}>
        <div>
          {this.usageAlert()}
          <LabControls labStore={this.store} />
          <br />
          {comps}
          {this.store.hasLabHours && <AutostopTimer labStore={this.store} />}
          {this.store?.userLab?.web_applications && (
            <LabWebApps labStore={this.store} />
          )}

          <Modal
            tabIndex="-1"
            show={this.store.modal.opened}
            onHide={() => {
              this.store.hideModal();
            }}
          >
            <ModalHeader tabIndex="-1" closeButton>
              <ModalTitle>{this.store.modal.title}</ModalTitle>
            </ModalHeader>
            <ModalFooter tabIndex="-1">
              {this.store.modal.confirmText ? (
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    this.store.modalDoConfirm();
                  }}
                  disabled={this.store.modal.working}
                >
                  {this.store.modal.confirmText}
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  this.store.hideModal();
                }}
                disabled={this.store.modal.working}
              >
                {this.store.modal.dismissText}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Provider>
    );
  }
}

/*
  debug elements
        <p>Lab Definition</p>
        <pre>{JSON.stringify(this.store.labDefinition, null, 2)}</pre>
        <p>User Lab</p>
        <pre>{JSON.stringify(this.store.userLab, null, 2)}</pre>
*/

export default LabEnvironment;
