const FAQSTRIAL = [
  {
    id: 1,
    center: true,
    key: 1,
    title: 'Learner Lab Experience',
  },
  {
    id: 2,
    icon: 'Q1',
    class: 'faq-icon',
    key: 2,
    center: false,
    question: 'What is auto-stop and auto-destroy in the lab environment?',
    answer: [
      {
        key: 3,
        class: 'list-item',
        text: 'To prevent lab environment overuse and to be able to serve as many customers and partners as possible, labs are automatically stopped (auto-stop) after 1 hour. If the lab is stopped and there is no activity in the lab within one day, the lab will be deleted (auto-destroy), so it is important to continue to work through the course and labs on a regular basis.',
      },
    ],
  },
  {
    id: 3,
    icon: 'Q2',
    class: 'faq-icon',
    key: 4,
    center: false,
    question: 'Is there a limit to the amount of lab time?',
    answer: [
      {
        key: 5,
        class: 'list-item',
        text: 'Yes, lab time is limited to 80 hours per course. Each course will provide access for 90 days and each lab will auto-stop after 1 hour unless the time is extended by the user and auto-destroy in 1 day unless extended.',
      },
    ],
  },
  {
    id: 4,
    icon: 'Q3',
    class: 'faq-icon',
    key: 6,
    center: false,
    question: 'Is there a limit to the amount of courses I can take?',
    answer: [
      {
        key: 7,
        class: 'list-item',
        text: 'No, there is no limit.',
      },
    ],
  },
  {
    id: 5,
    icon: 'Q4',
    class: 'faq-icon',
    key: 8,
    center: false,
    question: 'How do I prevent my labs from stopping?',
    answer: [
      {
        key: 9,
        class: 'list-item',
        text: 'To extend the labs, there is a plus sign indicator on the lab environment tab of your course, next to the auto-stop timer. Users can add one additional hour at a time. Users can also change the auto-destroy timer by selecting the plus sign indicator to add one additional day at a time. On the lab page, there is a video tutorial that walks through auto-stop and auto-destroy.',
      },
    ],
  },
  {
    id: 6,
    icon: 'Q5',
    class: 'faq-icon',
    key: 10,
    center: false,
    question: 'When I’m finished with the lab, how do I close the environment?',
    answer: [
      {
        key: 11,
        class: 'list-item',
        text: 'After working in a lab environment, if you need to continue your labs at a later time, you should click the STOP button to shutdown the virtual machine(s). If you are completely finished with your labs, you should click the DELETE button to destroy the virtual environment.',
      },
    ],
  },
  {
    id: 7,
    center: true,
    key: 12,
    title: 'Platform Support',
  },
  {
    id: 8,
    icon: 'Q6',
    class: 'faq-icon',
    key: 13,
    center: false,
    question:
      'How do I request support for issues with the lab environment and exercises?',
    answer: [
      {
        key: 14,
        class: 'list-item',
        text: 'For issues related to the lab environment - creating, opening, completing the exercises, extending your time, etc. - use the “Support” link at the top of the page tab to submit a support request.',
      },
    ],
  },
  {
    id: 9,
    icon: 'Q7',
    class: 'faq-icon',
    key: 15,
    center: false,
    question: 'What level of support will I receive?',
    answer: [
      {
        key: 16,
        class: 'list-item',
        text: 'You will have email support provided through a support form in the platform, available from the "Support" link at the top of the page. This support will cover technical lab issues, including access and connectivity. There is a service-level agreement (SLA) of one business day on all support tickets. Support is provided in English only. ',
      },
    ],
  },
  {
    id: 10,
    icon: 'Q8',
    class: 'faq-icon',
    key: 17,
    center: false,
    question:
      'How do I get support for issues NOT related to the lab environment and exercises?',
    answer: [
      {
        key: 18,
        class: 'list-item',
        text: 'For issues that are not related to the lab environment - the course assessment, earning completion in the Partner Training Portal, etc. - you should use the “Open a Support Case” link from the Guided Help menu in the Partner Training Portal. ',
      },
    ],
  },
  {
    id: 11,
    center: true,
    key: 19,
    title: 'Courses',
  },
  {
    id: 12,
    icon: 'Q9',
    class: 'faq-icon',
    key: 20,
    center: false,
    question: 'What happens when a new version of a course is released?',
    answer: [
      {
        key: 21,
        class: 'list-item',
        text: 'Partners will be notified when a new version of a course they’re already enrolled in has been released. At the time of release, there will be a grace period to complete the retiring version of the course but access to the old version of the course will be removed after the grace period. The new version of the course will remain available for enrollment.',
      },
    ],
  },
  {
    id: 13,
    icon: 'Q10',
    class: 'faq-icon',
    key: 22,
    center: false,
    question:
      'Have I completed the course once I have gone through the training material and lab?',
    answer: [
      {
        key: 23,
        class: 'list-item',
        text: 'Completing the final assessment at the end of the course will mark the course complete in the Partner Training Portal.',
      },
    ],
  },
  {
    id: 14,
    icon: 'Q11',
    class: 'faq-icon',
    key: 24,
    center: false,
    question: 'Where do I go for any other questions?',
    answer: [
      {
        key: 25,
        class: 'list-item',
        text: 'A full list of Frequently Asked Questions can be found on Partner Content Hub at the link below:',
      },
      {
        key: 26,
        class: 'list-item',
        link: 'https://app.highspot.com/items/621d1830338d5212ab50ac5e',
      },
    ],
  },
];

export default FAQSTRIAL;
