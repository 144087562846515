/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { computed, observable, action } from 'mobx';
import { VocabularyItem, VocabularyNamespaces } from 'types';
import { removeSessionData } from '../services/StorageService';

class UIStore {
  tFunction = (s) => s;

  @observable _title = '';

  @observable currentLanguage = 'en-US';

  @observable languages: VocabularyItem[] = [];

  @observable theme = 'default';

  rootStore: any;

  @observable currentCourseLanguages: string[] | null = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get title() {
    return this._title;
  }

  @computed get isChineseInstance() {
    const { configStore } = this.rootStore;
    return configStore.rezodePlatform === 'china';
  }

  set title(title) {
    this._title = title;
  }

  onLanguageChange = async (language = 'en-US') => {
    const { vocabularyStore } = this.rootStore;

    this.currentLanguage = language;

    try {
      await vocabularyStore.getVocabularyList(language);

      const languages = await vocabularyStore.getVocabularyByNamespace(
        VocabularyNamespaces.Languages,
        language,
      );

      this.languages = languages || this.languages;
      return this.languages.find((lang) => lang.token === language);
    } catch (e) {
      console.error(e);
    }
    return this.currentLanguage;
  };

  clearLabAutostartSettings = () => {
    removeSessionData('showLabAutostart');
  };

  /**
   * Stores which languages are supported by the self-paced course that is currently active
   * Used to highlight certain languages in the global UI when a course is open
   */
  @action reportActiveCourseTranslations(courseLanguages?: string[]) {
    if (courseLanguages) {
      this.currentCourseLanguages = courseLanguages;
    } else {
      this.currentCourseLanguages = null;
    }
  }
}

export default UIStore;
