import React, { useContext } from 'react';
import { useLocalStorageState } from '@ole-ui/ole-ui-components';
import SVG from 'react-inlinesvg';
import { Panel } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { storesContext } from 'stores';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import ComponentError from 'components/Error/ComponentError';
import CatalogCard from 'components/Catalog/Card';
import { handleError } from 'services/ErrorService';

import './styles.scss';

type Props = {
  limit?: number;
};

const RHLSTrialWidget = ({ limit = 30 }: Props) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useLocalStorageState(
    'ls001-widget-open-state',
    true,
  );
  const { userStore, catalogStore } = useContext(storesContext);

  const { RHLSTrialEntryProgressPair } = catalogStore;

  const handleToggle = () => {
    setIsOpened((prev) => !prev);
  };

  if (!userStore.isRHLSTrialSubscriber || !RHLSTrialEntryProgressPair) {
    return null;
  }

  const limitedList = limit
    ? RHLSTrialEntryProgressPair.slice(0, limit)
    : RHLSTrialEntryProgressPair;

  return (
    <Panel
      className="rhls-trial-widget"
      expanded={isOpened}
      onToggle={handleToggle}
      data-analytics-id="rhls-trial-widget-lp"
    >
      <Panel.Heading>
        <Panel.Title toggle>
          {t('Available in your Trial subscription')}
          <div className="rhls-trial-widget-expand">
            <SVG src={isOpened ? CollapseIcon : ExpandIcon}>
              {isOpened ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          {limitedList?.length === 0 ? (
            <div>{t('No offerings available')}</div>
          ) : (
            limitedList?.map?.((pair) => (
              <CatalogCard
                course={pair.entry}
                progress={Math.round(
                  (pair.progress?.total_progress || 0) * 100,
                )}
                key={pair.entry.doc_id}
              />
            ))
          )}
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

export default withErrorBoundary(
  observer(RHLSTrialWidget),
  ComponentError,
  handleError,
);
