import { action, observable, computed } from 'mobx';
import * as merge from 'deepmerge';
import { extractFromSlug } from 'helpers/utils';
import { search } from '../services/SearchService';

class SearchStore {
  @observable query = '';

  @observable searchResults = [];

  @observable suggestionResults = [];

  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable finishedSearch = true;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action search = async (searchTerm, courses = null) => {
    try {
      this.finishedSearch = false;
      const query = { q: searchTerm, size: 200 };
      if (courses) {
        query.course = courses;
      }
      const response = await search(query);
      const searchResults = response.hits?.hits;

      if (searchResults) {
        this.searchResults = searchResults;
        this.suggestionResults = response.suggest.suggestion;
      }
    } catch (e) {
      console.error(e);
    }
    this.finishedSearch = true;
    return this.searchResults;
  };

  @computed get totalPages() {
    return Math.ceil(this.results.length / this.entriesPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.results.slice(startIndex, startIndex + this.entriesPerPage);
  }

  @computed get options() {
    const { userStore } = this.rootStore;

    const optionArray = [
      {
        label: this.query,
        value: { query: this.query, searchWithin: 'all' },
      },
    ];

    if (!userStore?.isAlaCarte && !userStore?.isExternalSubscriber) {
      optionArray.push(
        {
          label: this.query,
          value: { query: this.query, searchWithin: 'catalog' },
        },
        {
          label: this.query,
          value: { query: this.query, searchWithin: 'skills-paths' },
        },
      );
    }

    return optionArray;
  }

  @computed get groupedSearchResults() {
    // API returns results in a flat array, so we need to group them by course code here
    const dict = {};
    const { catalogStore } = this.rootStore;

    if (!this.searchResults.length) {
      return [];
    }

    this.searchResults
      .slice()
      .sort((a, b) => {
        // Sort by scores, with higher scores first in the list
        return a._score < b._score ? 1 : -1;
      })
      .forEach((hit) => {
        let slug;
        let code;

        switch (hit._type) {
          case 'expert_seminar':
            slug = hit._id;
            ({ code } = extractFromSlug(slug));
            dict[code] =
              structuredClone(catalogStore.allUniqueCatalogEntries[code]) ||
              hit;
            dict[code] = merge(dict[code], { highlight: hit.highlight });
            break;
          case 'course':
            // eslint-disable-next-line
            slug = hit._source?.course_header?.version_tag;
            if (!slug) {
              [slug] = hit._id.split('@@');
            }
            try {
              ({ code } = extractFromSlug(slug));
            } catch (err) {
              return;
            }
            dict[code] =
              structuredClone(catalogStore.allUniqueCatalogEntries[code]) ||
              // eslint-disable-next-line
              hit._source?.course_header;

            break;
          case 'page':
            // eslint-disable-next-line
            slug = hit._source?.course_header?.version_tag;
            ({ code } = extractFromSlug(slug));

            if (!dict[code]) {
              dict[code] =
                structuredClone(catalogStore.allUniqueCatalogEntries[code]) ||
                // eslint-disable-next-line
                hit._source?.course_header;

              if (!dict[code]) {
                console.error(hit);
              }
            }
            if (!dict[code].pages) {
              dict[code].pages = {};
            }
            if (!dict[code].pages[slug]) {
              dict[code].pages[slug] = [];
            }
            dict[code].pages[slug].push(hit);
            break;
          default:
            break;
        }
      });

    return dict;
  }

  @computed get results() {
    return Object.values(this.groupedSearchResults);
  }

  @computed get suggestion() {
    const suggestedQuery = this.suggestionResults.map((entry) =>
      entry.options.length
        ? [entry.options[0].text, true]
        : [entry.text, false],
    );
    return suggestedQuery;
  }

  clearSearch = () => {
    this.query = '';
    this.searchResults = [];
  };
}

export default SearchStore;
