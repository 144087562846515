import lodashGet from 'lodash/get';
import { CatalogEntry } from 'types';
import { CatalogFilterConfiguration, CatalogFilterState } from './types';

/**
 *  Prioritizes a field in an entry based on an array of fields.
 * @param {CatalogEntry} entry  - the entry to prioritize the field for
 * @param {Array} fields  - an array of fields to prioritize
 * @param {Boolean} isArray - if true, the field is expected to be an array
 * @returns  the prioritized field value
 */
export const prioritizeEntryField = (
  entry: unknown,
  fields: string[],
  isArray: boolean,
) => {
  // TODO: conditional return type based on isArray
  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i];
    const value = lodashGet(entry, field);
    if (isArray) {
      if (Array.isArray(value) && value.length > 0) {
        return value;
      }
    } else if (value) {
      return value;
    }
  }
  return isArray ? [] : null;
};

/**
 * Flattens the filters for a given key and returns an array of the checked keys.
 */
export const flattenCheckedFilters = (
  key: string,
  filters: Record<string, CatalogFilterState[]>,
): string[] => {
  if (!Array.isArray(filters[key])) {
    return [];
  }
  return filters[key]
    .filter((item: CatalogFilterState) => item.checked)
    .map((item: CatalogFilterState) => item.key);
};

export const getFilteredEntries = <T>(
  /**
   * either a list of catalog entries or a list of objects that contain catalog entries
   */
  list: Array<T>,
  /**
   * an array of filter configurations
   */
  filterConfigurations: Pick<
    CatalogFilterConfiguration,
    'key' | 'validateEntry'
  >[],
  /**
   * a record of filter states. The key is the filter key and the value is either an array of CatalogFilterState objects or a record of key-value pairs
   */
  filterState:
    | Record<string, CatalogFilterState[]>
    | Record<string, Record<string, boolean>>,
  /**
   * (optional) a function that extracts the catalog entry from the object
   */
  entryAcessor: (obj: T) => CatalogEntry = (entry) =>
    entry as unknown as CatalogEntry,
): T[] => {
  if (!list) return [];

  return list.filter((e) => {
    const entry = entryAcessor(e);
    let entryMatch = true;

    if (!entry) return false;

    filterConfigurations.forEach((config) => {
      let currentFilterSelection: string[] = [];
      const state = filterState[config.key];

      if (state) {
        if (Array.isArray(state)) {
          currentFilterSelection = flattenCheckedFilters(config.key, {
            [config.key]: state,
          });
        } else {
          currentFilterSelection = Object.keys(state).filter(
            (key) => state[key],
          );
        }
      }

      if (!currentFilterSelection.length) return; // skip if no filters are selected

      const matchCriteria = config.validateEntry(entry, currentFilterSelection);
      if (matchCriteria === false) {
        entryMatch = false;
      }
    });

    return entryMatch;
  });
};
