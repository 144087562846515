import React, { useState } from 'react';
import { Tabs, Tab, TabTitleText } from '@patternfly/react-core';
import LabControlsStore, { labControlsContext } from 'stores/LabControls';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import LabStore from 'stores/Lab';
import { Helmet } from 'react-helmet';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import useMobxStores from 'hooks/useMobxStores';
import GenericViewErrorV2 from 'components/Error/GenericViewErrorV2';
import { useLocalStorageState } from '@ole-ui/ole-ui-components';

// tab related components
import LessonOverview from 'components/Course/LessonOverview';
import LabPolling from 'components/LabPolling';
import LabEnvironment from 'components/LabEnvironment';

import CourseStore from './store';
import './styles.scss';

// loading skeleton components
import PageSkeletonLoading from '../skeletons/PageSkeletonLoading';
import ToolbarSkeletonLoading from '../skeletons/ToolbarSkeletonLoading';
import CourseHeaderSkeletonLoading from '../skeletons/CourseHeaderSkeletonLoading';

import BundledCourse from '../BundledCourse';

// layout components
import CourseHeader from './CourseHeader';
import CourseToolbar from './CourseToolbar';
import CourseHUD from './CourseHUD';

// tab related components (local)
import CourseTab from './Tabs/CourseTab';
import ExpertExtrasTab from './Tabs/ExpertExtrasTab';
import LabPlusTab from './Tabs/LabPlusTab';

import useCourseCompletionNotification from '../useCourseCompletionNotification';
import useLoadCourse from './hooks/useLoadCourse';
import usePageChange from './hooks/usePageChange';
import ErrorState from './ErrorState';
import useAutoMoveIntercom from './hooks/useAutoMoveIntercom';

enum CourseTabKey {
  Overview = 'overview',
  Toc = 'toc',
  Course = 'course',
  OnlineLab = 'online_lab',
  LabEnv = 'lab_env',
  ExpertExtras = 'expert_extras',
  LabPlus = 'lab_plus',
  Bookmarks = 'bookmarks',
}

const CourseView = () => {
  const { t } = useTranslation();
  const {
    routerStore,
    progressStore,
    uiStore,
    userStore,
    classesStore,
    eventStore,
    catalogStore,
    configStore,
  } = useMobxStores();
  const [activeTabKey, setActiveTabKey] = useState<CourseTabKey>(
    CourseTabKey.Course,
  );

  // initialize local stores
  const labControlsStore = useLocalStore(() => new LabControlsStore());
  const labStore = useLocalStore(() => new LabStore());
  const courseStore = useLocalStore(
    () =>
      new CourseStore(
        routerStore,
        uiStore,
        userStore,
        catalogStore,
        progressStore,
        classesStore,
        labStore,
        eventStore,
        configStore,
      ),
  );

  const { labBlueprints, labDefinition } = labStore;

  // getting route params
  const { route } = routerStore;
  const { course: courseSlug, page: pageIdParam } = (route as any).params;

  const {
    isBundledCourse,
    isLoadingInitialData,
    canShowOverviewTab,
    canShowLabPlusTab,
    canShowExpertExtras,
    courseTotalProgress,
    translations,
    isLesson,
  } = courseStore;

  const [direction, setDirection] = useState('upward');
  const handleDirectionChange = (hudDirection) => {
    setDirection(hudDirection);
  };

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useLocalStorageState(
    'course-hud-video-player-enabled',
    true,
  );

  const handleVideoPlayerChange = (enabled: boolean) => {
    setIsVideoPlayerEnabled(enabled);
  };

  useAutoMoveIntercom();

  // load course and complementary data
  useLoadCourse(courseSlug, courseStore);

  // change page content on page change
  usePageChange(pageIdParam, courseStore, () => {
    setActiveTabKey(CourseTabKey.Course);
  });

  useCourseCompletionNotification(
    courseStore.canShowCourseCompletionNotification,
    courseTotalProgress,
    courseSlug,
  );

  const handleTabClick = (_event: any, tabIndex: CourseTabKey) => {
    setActiveTabKey(tabIndex);
  };

  const loadingState = (
    <div className="container-grid-responsive hud-course-view hud-course-view--loading">
      <CourseHeaderSkeletonLoading />
      <ToolbarSkeletonLoading />
      <div style={{ marginTop: '180px' }}>
        <PageSkeletonLoading />
      </div>
    </div>
  );

  if (courseStore.initialLoadError) {
    return (
      <ErrorState
        onRetry={() => {
          courseStore.getInitialData(courseSlug);
        }}
        onSupportClick={() => {
          eventStore.toggleDrawer('support_form');
        }}
        initialErrorCount={courseStore.initialErrorCount}
      />
    );
  }

  if (isBundledCourse) {
    const completeBundleCourseProgress = async () => {
      try {
        await progressStore.setCourseProgress(courseStore.courseSlug);
      } catch (error) {
        console.error('Cannot complete course:', error);
      }
    };

    return (
      <BundledCourse
        onComplete={completeBundleCourseProgress}
        bundleReference={courseStore.courseCatalogEntry.bundle_reference}
        showToolbar={!courseStore.isFreeToolbar}
        prepareFeedback={courseStore.openFeedbackDrawer}
        title={courseStore.title}
      />
    );
  }

  const courseHUD = (
    <CourseHUD
      key="course-hud"
      courseStore={courseStore}
      onDirectionChange={handleDirectionChange}
      isVideoPlayerEnabled={isVideoPlayerEnabled}
      onVideoPlayerChange={handleVideoPlayerChange}
      onOverviewButtonClicked={() => {
        setActiveTabKey(CourseTabKey.Overview);
      }}
    />
  );

  const content = (
    <div className="container-grid-responsive hud-course-view">
      <CourseHeader
        courseSlug={courseSlug}
        courseStore={courseStore}
        labControlsStore={labControlsStore}
      />
      {courseStore.loadedInitialData && (
        <>
          <CourseToolbar courseSlug={courseSlug} courseStore={courseStore} />
          <Tabs
            className="hud-course-view__tabs"
            isFilled
            isBox
            activeKey={activeTabKey}
            onSelect={handleTabClick}
            aria-label={t('Course related tabs')}
            role="region"
            isOverflowHorizontal
          >
            {canShowOverviewTab && (
              <Tab
                eventKey={CourseTabKey.Overview}
                title={<TabTitleText>{t('Overview')}</TabTitleText>}
              >
                <div className="hud-course-view__tab-content">
                  <LessonOverview
                    currentLanguage={uiStore.currentLanguage}
                    metadata={courseStore.courseMetadata}
                    courseCatalogEntry={courseStore.courseCatalogEntry}
                    languages={translations}
                  />
                </div>
              </Tab>
            )}
            <Tab
              eventKey={CourseTabKey.Course}
              title={
                <TabTitleText>
                  {isLesson ? t('Lesson') : t('Course')}
                </TabTitleText>
              }
            >
              <CourseTab
                courseStore={courseStore}
                isVideoPlayerEnabled={isVideoPlayerEnabled}
              />
            </Tab>
            {labBlueprints.length && !labDefinition.doc_id && (
              <Tab
                eventKey={CourseTabKey.OnlineLab}
                title={<TabTitleText>{t('Online Lab')}</TabTitleText>}
              >
                <labControlsContext.Provider value={labControlsStore}>
                  <div className="hud-course-view__tab-content">
                    <LabPolling
                      blueprints={labBlueprints}
                      courseSlug={courseSlug}
                    />
                  </div>
                </labControlsContext.Provider>
              </Tab>
            )}
            {labDefinition.doc_id && (
              <Tab
                eventKey={CourseTabKey.LabEnv}
                title={<TabTitleText>{t('Lab Environment')}</TabTitleText>}
              >
                <div
                  id="tab-course-lab-environment"
                  className="hud-course-view__tab-content"
                >
                  <LabEnvironment
                    courseSlug={courseSlug}
                    labDefinition={labDefinition}
                    enrollmentUuid={courseStore.euuid}
                  />
                </div>
              </Tab>
            )}
            {canShowExpertExtras && (
              <Tab
                eventKey={CourseTabKey.ExpertExtras}
                title={<TabTitleText>{t('Expert Extras')}</TabTitleText>}
              >
                <ExpertExtrasTab playListId={courseStore.playListId} />
              </Tab>
            )}
            {canShowLabPlusTab && (
              <Tab
                eventKey={CourseTabKey.LabPlus}
                title={<TabTitleText>{t('Lab Plus')}</TabTitleText>}
              >
                <LabPlusTab courseStore={courseStore} />
              </Tab>
            )}
          </Tabs>
        </>
      )}
    </div>
  );

  let pageTitle = t('Course - Red Hat Learning');
  if (courseStore.title) {
    if (courseStore.pageSlug) {
      pageTitle = `${courseStore.pageSlug} - ${courseStore.title} - Red Hat Learning`;
    } else {
      pageTitle = `${courseStore.title} - Red Hat Learning`;
    }
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {direction === 'downward' ? courseHUD : null}
      {isLoadingInitialData ? loadingState : content}
      {direction === 'upward' ? courseHUD : null}
    </>
  );
};

export default withErrorBoundary(
  observer(CourseView),
  GenericViewErrorV2,
  handleError,
);
