import { get, post, put } from 'axios';
import {
  EXAM_SCHEDULER_API_URL,
  EXAM_COLLATERAL_API_URL,
  EXAM_STATUS_MANAGEMENT_API_URL,
  USER_EXAM_ENROLL_API,
  USER_EXAM_STATUS_API,
  EXAM_FAQ_API,
  EXAM_READINESS_API,
} from '../config/constants';

export const getExamCollateral = async (code, language = 'en-US') => {
  if (!code) {
    throw new Error('Course code is required');
  }

  try {
    const collateral = await get(
      `${EXAM_COLLATERAL_API_URL}${code}@@${language}`,
    );
    return collateral.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getExamFaqPlaylist = async () => {
  try {
    const faqPlaylist = await get(EXAM_FAQ_API);

    return faqPlaylist.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getExamChecklists = async (code) => {
  try {
    const examChecklists = await get(EXAM_READINESS_API, { params: { code } });

    return examChecklists.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const checkExamStatus = async (code, version) => {
  if (!code || !version) {
    throw new Error('Course code and version are required');
  }

  try {
    const examInfo = await get(`${EXAM_SCHEDULER_API_URL}${code}-${version}`);
    return examInfo.data;
  } catch (error) {
    return { status: 'unenrolled' };
  }
};

export const getUserExams = async () => {
  try {
    const examInfo = await get(`${EXAM_SCHEDULER_API_URL}`);
    return examInfo.data.items;
  } catch (error) {
    return [];
  }
};

export const getReporteesExamStatus = async (users) => {
  try {
    const request = { users };
    const examData = await post(`${EXAM_STATUS_MANAGEMENT_API_URL}`, request);
    return examData.data.items;
  } catch (err) {
    throw err;
  }
};

export const getExamStatus = async (sku) => {
  if (!sku) {
    throw new Error('Exam sku is required');
  }
  try {
    const examInfo = await post(USER_EXAM_STATUS_API, {
      sku,
    });
    return examInfo.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

export const enrollIntoExam = async (code) => {
  if (!code) {
    throw new Error('Exam code is required');
  }

  try {
    const examInfo = await post(`${EXAM_SCHEDULER_API_URL}`, {
      exam_code: `${code}`,
    });
    return examInfo.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

export const enrollIntoExamV2 = async (sku) => {
  if (!sku) {
    throw new Error('Exam sku is required');
  }

  try {
    const examInfo = await post(`${USER_EXAM_ENROLL_API}`, {
      sku,
    });
    if (!examInfo.data) {
      throw new Error('Could not enroll into the exam.');
    }
    return examInfo.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};

export const cancelExam = async (orderNumber) => {
  if (!orderNumber) {
    throw new Error('Exam order number is required');
  }

  try {
    await put(`${EXAM_SCHEDULER_API_URL}${orderNumber}/`, {
      operation: 'cancel',
    });
  } catch (error) {
    throw error;
  }
};
