import React, { useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalVariant } from '@patternfly/react-core';
import { withErrorBoundary } from 'react-error-boundary';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'moment-duration-format';
import ComponentError from 'components/Error/ComponentError';
import { OfferingStats } from '@ole-ui/ole-ui-components';
import { handleError } from 'services/ErrorService';
import isNil from 'lodash/isNil';

import DurationIcon from 'img/icons/estimated-duration.svg';
import QuizIcon from 'img/icons/quiz.svg';
import LabIcon from 'img/icons/lab-2.svg';
import GuidedExerciseIcon from 'img/icons/guided-exercise.svg';
import VideoIcon from 'img/icons/video.svg';
import LevelIcon from 'img/icons/level.svg';

import useMobxStores from 'hooks/useMobxStores';
import { VocabularyItem, VocabularyNamespaces } from 'types';
import LessonIndicatorLegends from './Legends';

import './styles.scss';

type Props = {
  includeLabels?: boolean;
  estimatedTime?: number;
  GECount?: number;
  quizCount?: number;
  labCount?: number;
  videoTime?: number;
  durationUnit?: string;
  level?: number;
  product?: string;
  audience?: string;
  competencies?: string;
  disableLegend?: boolean;
};

const LessonIndicators = ({
  includeLabels,
  estimatedTime,
  GECount,
  quizCount,
  labCount,
  videoTime,
  durationUnit = 'minutes',
  level,
  product,
  audience,
  competencies,
  disableLegend = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { vocabularyStore } = useMobxStores();
  const timeFormat = t('h[h] mm[min]'); // might be different depending on the language

  const labelLevel = useMemo(() => {
    if (!isNil(level)) {
      const offeringLevelVocabulary: { [keys: string]: VocabularyItem } =
        vocabularyStore.vocabularyMap[
          `${VocabularyNamespaces.OfferingLevel}@@${i18n.language}`
        ]?.vocabulary;

      if (offeringLevelVocabulary) {
        return Object.values(offeringLevelVocabulary).find(
          (item) => item?.token === level.toString(),
        ).display_name;
      }
    }

    return null;
  }, [vocabularyStore.vocabularyMap]);

  const formattedEstimatedDuration: string | undefined = useMemo(() => {
    if (estimatedTime) {
      return moment
        .duration({
          [durationUnit]: estimatedTime,
        })
        .format?.(timeFormat);
    }
    return undefined;
  }, [estimatedTime, durationUnit]);

  const formattedVideoDuration: string | undefined = useMemo(() => {
    if (videoTime) {
      return moment
        .duration({
          [durationUnit]: videoTime,
        })
        .format?.(timeFormat);
    }
    return undefined;
  }, [videoTime, durationUnit]);

  const handleModalToggle = () => {
    if (!disableLegend) {
      setIsModalOpen((prev) => !prev);
    }
  };

  return (
    <div className="lesson-indicators">
      <Modal
        title={t('Legend')}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        variant={ModalVariant.medium}
      >
        <LessonIndicatorLegends
          hasEstimatedTime={!isNil(formattedEstimatedDuration)}
          hasGECount={!isNil(GECount)}
          hasQuizCount={!isNil(quizCount)}
          hasLabCount={!isNil(labCount)}
          hasVideoTime={!isNil(formattedVideoDuration)}
          hasLevel={!isNil(level)}
        />
      </Modal>

      <OfferingStats onClick={handleModalToggle}>
        {formattedEstimatedDuration && (
          <OfferingStats.Item
            label={includeLabels ? t('Duration:') : undefined}
            icon={<SVG src={DurationIcon} title={t('Estimated duration')} />}
          >
            {formattedEstimatedDuration}
          </OfferingStats.Item>
        )}
        {!isNil(GECount) && (
          <OfferingStats.Item
            label={includeLabels ? t('Guided exercises:') : undefined}
            icon={
              <SVG src={GuidedExerciseIcon} title={t('Guided exercises')} />
            }
          >
            {GECount}
          </OfferingStats.Item>
        )}
        {!isNil(labCount) && (
          <OfferingStats.Item
            label={includeLabels ? t('Labs:') : undefined}
            icon={<SVG src={LabIcon} title={t('Labs')} />}
          >
            {labCount}
          </OfferingStats.Item>
        )}
        {!isNil(quizCount) && (
          <OfferingStats.Item
            label={includeLabels ? t('Quizzes:') : undefined}
            icon={<SVG src={QuizIcon} title={t('Quizzes')} />}
          >
            {quizCount}
          </OfferingStats.Item>
        )}
        {formattedVideoDuration && (
          <OfferingStats.Item
            label={includeLabels ? t('Videos:') : undefined}
            icon={<SVG src={VideoIcon} title={t('Total video time')} />}
          >
            {formattedVideoDuration}
          </OfferingStats.Item>
        )}
        {!isNil(labelLevel) && (
          <OfferingStats.Item
            label={includeLabels ? t('Difficulty level:') : undefined}
            icon={<SVG src={LevelIcon} title={t('Difficulty level')} />}
          >
            {labelLevel}
          </OfferingStats.Item>
        )}
      </OfferingStats>

      {/* Tags: */}
      <div className="lesson-indicators__tags">
        {!isNil(product) && (
          <OfferingStats.Item label={t('Product:')}>
            {product}
          </OfferingStats.Item>
        )}
        {!isNil(audience) && (
          <OfferingStats.Item label={t('Audience:')}>
            {audience}
          </OfferingStats.Item>
        )}
        {!isNil(competencies) && (
          <OfferingStats.Item label={t('Competencies:')}>
            {competencies}
          </OfferingStats.Item>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(
  observer(LessonIndicators),
  ComponentError,
  handleError,
);
