/* eslint-disable consistent-return */
import { createContext } from 'react';
import { action, computed, observable, toJS } from 'mobx';
import * as merge from 'deepmerge';
import moment from 'moment';
import { getCourseInfo, getCourseInfoMeta } from 'services/ClassroomService';
import {
  getCoursePage,
  getCourseStructure,
  getCourseOffering,
  createUpdateCourseBookmark,
  getCourseCollateral,
  getCourseMetadata,
} from 'services/CourseService';
import { postEvent } from 'services/EventService';
import {
  getCourseEnrollment,
  getEnrollmentInstance,
  saveEnrollmentUserData,
  getUserEnrollmentInfo,
  saveUserEnrollmentInfo,
} from 'services/UserEnrollmentService';
import notifications from 'mocks/notifications.mock';
import { DEFAULT_LANGUAGE, ZDOC_COURSE_INFO } from 'config/constants';
import ZDocManagerStore from 'stores/ZdocManager';
import { CourseType, Modality } from 'types';
import NewCourseViewStore from '../CourseViewV2/store';

class LegacyCourseStore {
  @observable _activeTab = 1;

  @observable _enrollment = {};

  @observable enrollmentFetched = false;

  @observable userEnrollmentInfo = null;

  @observable _pageSlug;

  @observable _pageTitle;

  @observable languageOverride;

  @observable routerStore;

  @observable uiStore;

  @observable userStore;

  @observable euuid;

  @observable courseSlug = '';

  @observable courseInfo = {};

  @observable courseOffering = {};

  @observable courseInfoFetched = false;

  @observable coursePageFetchInProgress = false;

  @observable page = {};

  @observable collateral;

  @observable courseMetadata;

  @observable notifications = {
    showLab: false,
    showLabAlert: false,
    showSurvey: false,
    showSurveyAlert: false,
  };

  // TODO: Need to vet this
  @observable courseStructure = [];

  @observable offeringSettings = {};

  @observable isBooked = false;

  @observable bookmarkToSave = {};

  @observable courseInfoMeta = {};

  @observable videosInCourse = {};

  constructor(routerStore, uiStore, userStore, catalogStore) {
    this.routerStore = routerStore;
    this.uiStore = uiStore;
    this.userStore = userStore;
    this.catalogStore = catalogStore;
    this.zdocManagerStore = new ZDocManagerStore(ZDOC_COURSE_INFO);
  }

  @computed get videoWidgetTitle() {
    if (this.coursePageFetchInProgress) {
      return '...';
    }

    const trancuate = (anyTitle, maxLength) => {
      return anyTitle.length > maxLength
        ? `${anyTitle.slice(0, maxLength - 1)} ...`
        : anyTitle;
    };
    const trancuatedCourseTitle = trancuate(this.title, 70);
    const trancuatedSectionTitle = trancuate(this.pageTitle, 70);

    return ` - ${trancuatedCourseTitle} | ${trancuatedSectionTitle}`;
  }

  @computed get playListId() {
    if (!this.expertExtras) {
      return null;
    }

    const playlists = this.expertExtras.kaltura_playlists;
    const result = playlists.filter((obj) => {
      return obj.playlist_name === 'main';
    });
    return result[0].id;
  }

  @computed get enrollment() {
    return this._enrollment?.core || {};
  }

  set enrollment(enrollment) {
    this._enrollment = enrollment;
  }

  @computed get completionTime() {
    return this.enrollment.completion_time;
  }

  @computed get userData() {
    const { user_data: userData } = this._enrollment;
    return userData;
  }

  @computed get language() {
    return (
      this.languageOverride ||
      this.routerStore?.route?.params?.language ||
      this.userData?.language ||
      this.uiStore.currentLanguage ||
      'en-US'
    );
  }

  @computed get pageSlug() {
    if (!(this.courseStructure.length && this.enrollmentFetched)) {
      return this._pageSlug;
    }

    const { page_tag: firstPageSlug } =
      this.courseStructure && this.courseStructure[0];

    return this._pageSlug || firstPageSlug;
  }

  set pageSlug(pageSlug) {
    this._pageSlug = pageSlug;
  }

  @computed get activeTab() {
    return this._activeTab;
  }

  @computed get courseCatalogEntry() {
    const { courseCode, courseVersion } = this;

    const code = `${courseCode}`.toLowerCase().replace('vc', '');

    if (this.userStore?.isAlaCarte) {
      return this.catalogStore?.groupedAllCatalogEntries?.[code]?.[
        courseVersion
      ];
    }

    return this.catalogStore?.groupedCatalogEntries?.[code]?.[courseVersion];
  }

  set activeTab(tab) {
    this._activeTab = tab;
  }

  @computed get isEarlyAccess() {
    const { dynamic_info: dynamicInfo } = this.courseInfo || {};
    return dynamicInfo?.summaries[0]?.status === 'early';
  }

  @computed get isLabPlus() {
    return this.courseCatalogEntry?.course_type === CourseType.LabPlus;
  }

  @computed get courseCode() {
    const { dynamic_info: dynamicInfo } = this.courseInfo || {};
    return dynamicInfo?.code;
  }

  @computed get courseVersion() {
    const { dynamic_info: dynamicInfo } = this.courseInfo || {};
    return dynamicInfo?.current;
  }

  @computed get currentCourse() {
    return NewCourseViewStore.getCurrentCourse(this.courseInfo, this.language);
  }

  @computed get buildEnv() {
    return this.page?.build_env || {};
  }

  @computed get content() {
    return this.page?.content?.html || '';
  }

  @computed get pageUUID() {
    return this.page?.uuid || '';
  }

  @computed get pageTitle() {
    return this.page?.title || '';
  }

  @computed get modality() {
    return this.currentCourse?.modality;
  }

  @computed get isCourse() {
    return this.currentCourse?.modality === Modality.Course;
  }

  @computed get isLesson() {
    return (
      this.currentCourse?.modality === Modality.Course &&
      this.courseCatalogEntry?.course_type === CourseType.Lesson
    );
  }

  @computed get isVideoClassroom() {
    return this.currentCourse?.modality === Modality.VideoClassroom;
  }

  @computed get title() {
    if (this.isVideoClassroom) {
      return null;
    }

    return this.currentCourse?.title;
  }

  @computed get translations() {
    const { dynamic_info: dynamicInfo } = this.courseInfo;
    return dynamicInfo?.variants[dynamicInfo?.current] || [];
  }

  @computed get versions() {
    const { dynamic_info: dynamicInfo } = this.courseInfo;
    const versionList = Object.keys(dynamicInfo?.variants || []);

    return versionList;
  }

  @computed get epochVersions() {
    const { dynamic_info: dynamicInfo } = this.courseInfo;
    return dynamicInfo?.epoch_version_number || {};
  }

  @computed get sortedEpochVersions() {
    return Object.keys(this.epochVersions)
      .map((version) => {
        return [version, this.epochVersions[version]];
      })
      .sort((a, b) => {
        return a[1] - b[1];
      })
      .map((e) => {
        return e[0];
      });
  }

  @computed get sortedVersions() {
    // filter to exclude epoch versions that are in the product versions list and sort
    const sortedVersions = this.versions
      .filter((version) => !this.sortedEpochVersions.includes(version))
      .map((a) =>
        a
          .split('.')
          .map((n) => +n + 100000)
          .join('.'),
      )
      .sort()
      .map((a) =>
        a
          .split('.')
          .map((n) => +n - 100000)
          .join('.'),
      );

    return sortedVersions.concat(this.sortedEpochVersions);
  }

  @computed get latestVersion() {
    if (!this.sortedVersions.length) {
      return null;
    }

    return this.sortedVersions.slice(-1)[0];
  }

  @computed get isLatestVersion() {
    return (
      this.latestVersion && this.currentCourse?.version === this.latestVersion
    );
  }

  @computed get showAutostart() {
    // eslint-disable-next-line camelcase
    const preference = this.enrollment?.user_data?.settings?.show_autostart;
    if (preference !== undefined && preference !== null) {
      return preference;
    }
    return true;
  }

  @computed get showSurvey() {
    // eslint-disable-next-line camelcase
    const preference = this.userData?.settings?.show_survey50;
    if (preference !== undefined && preference !== null) {
      return preference;
    }
    return true;
  }

  @computed get contentNotFound() {
    return this.courseInfoFetched && !this.courseInfo.dynamic_info?.code;
  }

  @computed get enrollmentNotFound() {
    return this.enrollmentFetched && !this.euuid;
  }

  @computed get showSurvey25Dialog() {
    return !this.userStore.isOpenSubscriber;
  }

  @computed get showSurvey50Dialog() {
    return !this.userStore.isOpenSubscriber;
  }

  @computed get showFeedback() {
    return !this.userStore.isOpenSubscriber;
  }

  @computed get showCertAttendanceButton() {
    return !this.userStore.isOpenSubscriber;
  }

  @computed get isFirstTwoChapters() {
    return /pr01|ch01|ch02/.test(this.pageSlug);
  }

  @computed get isVideoAllowed() {
    return (
      !this.userStore.isOpenSubscriber &&
      !this.isBlockedForFreeTier &&
      this.pageWithVideo
    );
  }

  @computed get isBlockedForFreeTier() {
    return this.userStore.isFreeTierSubscriber && !this.isFirstTwoChapters;
  }

  @action getCourseEnrollment = async (username) => {
    const enrollment = await getCourseEnrollment(this.courseSlug, username);

    this.euuid = enrollment?.uuid || this.euuid;
    return enrollment;
  };

  @action joinCourse = async () => {
    const event = await postEvent('offering_enter', {
      data: {
        offering_slug: this.courseSlug,
        enrollment_uuid: this.euuid,
      },
    });

    return event;
  };

  @action getEnrollmentInstance = async () => {
    try {
      const enrollment = await getEnrollmentInstance(this.euuid);
      this.enrollment = enrollment;
    } finally {
      return this.enrollment;
    }
  };

  @action saveEnrollmentUserData = async (language, settings) => {
    if (this.euuid) {
      if (language) {
        this.languageOverride = language;
      }

      if (settings) {
        this.offeringSettings = settings;
      }

      const enrollment = await getEnrollmentInstance(this.euuid);
      let { user_data: oldUserData } = toJS(enrollment || {});

      if (!oldUserData) {
        oldUserData = {};
      }

      const newUserData = merge(oldUserData, {
        bookmark: this.pageSlug,
        settings: this.offeringSettings,
        ...(language && { language }),
      });

      const newEnrollment = await saveEnrollmentUserData(
        newUserData,
        this.euuid,
      );
      return newEnrollment;
    }
  };

  @action getUserEnrollmentInfo = async () => {
    try {
      this.userEnrollmentInfo = await getUserEnrollmentInfo(
        this.userStore.username,
        this.courseSlug,
      );
    } finally {
      return this.userEnrollmentInfo;
    }
  };

  @action saveUserEnrollmentInfo = async () => {
    const newUserEnrollmentInfo = {
      ...this.userEnrollmentInfo,
      last_page_accessed: this.pageSlug,
    };

    const userEnrollmentInfo = await saveUserEnrollmentInfo(
      newUserEnrollmentInfo,
      this.userStore.username,
      this.courseSlug,
    );
    return userEnrollmentInfo;
  };

  @action getCourseInfo = async () => {
    let courseInfo;
    try {
      courseInfo = await getCourseInfo(this.courseSlug);
    } catch (err) {
      console.error('Failed to get course info:', err);
      return null;
    } finally {
      this.courseInfoFetched = true;
    }

    this.courseInfo = courseInfo || this.courseInfo;

    const { dynamic_info: dynamicInfo } = this.courseInfo;
    const translations = dynamicInfo?.variants[dynamicInfo?.current] || [];
    this.uiStore.reportActiveCourseTranslations(translations);

    return this.courseInfo;
  };

  @action getCourseInfoMeta = async () => {
    try {
      this.courseInfoMeta = await getCourseInfoMeta(this.courseSlug);
      return this.courseInfoMeta;
    } catch (err) {
      return null;
    }
  };

  @computed get expertExtras() {
    return this.courseInfoMeta?.expert_extras;
  }

  @computed get labPlusCourses() {
    return this.courseInfoMeta?.lab_plus;
  }

  @computed get hasLabPlusCourseInfo() {
    return (
      this.courseInfoMeta?.doc_id === this.courseSlug &&
      this.labPlusCourses?.code.length > 0
    );
  }

  @computed get hasExpertExtrasInfo() {
    return (
      this.courseInfoMeta?.doc_id === this.courseSlug &&
      this.expertExtras?.kaltura_playlists.length > 0
    );
  }

  @action getCourseStructure = async () => {
    const structure = await getCourseStructure(this.courseSlug, this.language);
    this.courseStructure = structure || this.courseStructure;
    return this.courseStructure;
  };

  @action getCoursePage = async () => {
    if (this.courseSlug && this.pageSlug && !this.coursePageFetchInProgress) {
      try {
        this.coursePageFetchInProgress = true;

        const page = await getCoursePage(
          this.courseSlug,
          this.pageSlug,
          this.language,
        );

        if (!page) {
          return {};
        }

        this.page = page;
      } catch (fetchPageError) {
        console.error(fetchPageError);
      } finally {
        this.coursePageFetchInProgress = false;
      }
    }
    return this.page;
  };

  @computed get favoriteList() {
    let favoriteArray = [];

    if (this.userData && this.userData.favorites) {
      const { favorites } = toJS(this.userData);
      favoriteArray = favorites.filter((item, index) => {
        return favorites.indexOf(item) === index;
      });
    }

    return favoriteArray;
  }

  @action getCourseOffering = async () => {
    try {
      const courseOffering = await getCourseOffering(this.courseSlug);
      this.courseOffering = courseOffering;
    } catch (err) {
      this.courseOffering = {};
    } finally {
      return this.courseOffering;
    }
  };

  @computed get bookmarkList() {
    let bookmarkArray = [];

    if (this.courseOffering && this.courseOffering.bookmarks) {
      const { bookmarks } = this.courseOffering;
      bookmarkArray = bookmarks.filter((item, index) => {
        return bookmarks.indexOf(item) === index;
      });
    }

    return bookmarkArray;
  }

  @action checkBookmark = (sectionSlug) => {
    if (this.courseOffering) {
      const slugs = [];
      this.bookmarkList.map((fav) => slugs.push(fav.section));
      this.isBooked = slugs.indexOf(sectionSlug) !== -1;
    }
  };

  @action handleBookmark = async (section, title) => {
    if (!section && !title) {
      return {};
    }
    try {
      const courseOffering = await createUpdateCourseBookmark(
        this.courseSlug,
        section,
        title,
      );

      this.courseOffering = courseOffering;
    } finally {
      return this.courseOffering;
    }
  };

  @action getCourseCollateral = async () => {
    // Note: this is only meant for lessons. If we end up using this for early access,
    // we need to address the "ea" substring before getting the collateral.
    const { courseCode: code, courseVersion: version, language } = this;
    if (this.collateral?.doc_id === `${code}@@${version}@@${language}`) {
      return this.collateral;
    }

    let collateral;
    try {
      collateral = await getCourseCollateral(code, version, language);
    } catch (error) {
      try {
        // if no collateral available in user's preferred language, fetch in English
        collateral = await getCourseCollateral(code, version, DEFAULT_LANGUAGE);
      } catch (retryError) {
        console.error(retryError);
      }
    }

    if (collateral) {
      this.collateral = collateral;
    }

    return this.collateral;
  };

  @action getCourseMetadata = async () => {
    let courseMetadata;
    try {
      courseMetadata = await getCourseMetadata(this.courseSlug);
      this.courseMetadata = courseMetadata;
    } catch (error) {
      console.error('Failed to get course metadata:', error);
      return null;
    }
  };

  @computed get showDecomissionBanner() {
    const { courseCode, courseVersion } = this;
    if (
      (courseCode === 'do180vc' && courseVersion === '4.0') ||
      (courseCode === 'do288vc' && courseVersion === '4.1')
    ) {
      return true;
    }

    return false;
  }

  @computed get labMaintenanceBanners() {
    const today = moment();
    const { courseSlug } = this;
    const labMaintenanceNotifications = notifications.filter(
      (n) => n.type === 'lab_maintenance',
    );

    return labMaintenanceNotifications.filter((n) => {
      const toDate = moment.unix(n.to_date);
      const fromDate = moment.unix(n.from_date);
      return (
        n.course_slugs.includes(courseSlug.toLowerCase()) &&
        today.isBetween(fromDate, toDate)
      );
    });
  }

  @action async getPageVideosForCourse() {
    try {
      const courseSlug = `${this.courseCode}-${this.courseVersion}`;
      this.zdocManagerStore.terms = { _id: courseSlug };
      const _courseInfoDocs = await this.zdocManagerStore.fetch();

      this.videosInCourse =
        _courseInfoDocs && _courseInfoDocs.length >= 1
          ? _courseInfoDocs[0]
          : {};
    } catch (e) {
      this.videosInCourse = {};
      console.error(
        'Error while getting list of videos for all pages for a course',
        e,
      );
    } finally {
      return this.videosInCourse;
    }
  }

  @computed get totalVideoCount() {
    return this.videosInCourse?.page_videos?.reduce((acc, page) => {
      return acc + page.entries.length;
    }, 0);
  }

  @computed get customVideoPlayerId() {
    return this.videosInCourse?.custom_player_id;
  }

  @computed get pageWithVideo() {
    const videosForCurrentPage = this.videosInCourse?.page_videos?.find(
      (v) => v.page_slug === this.pageSlug,
    )?.entries;

    const _defaultLanguage = 'en-US';
    const _currentLanguage = this.language || _defaultLanguage;

    let videosForCurrentPageByLanguage =
      videosForCurrentPage?.length > 0 &&
      videosForCurrentPage?.filter((v) => v.language === _currentLanguage);
    videosForCurrentPageByLanguage =
      videosForCurrentPageByLanguage?.length > 0
        ? videosForCurrentPageByLanguage
        : videosForCurrentPage?.filter((v) => v.language === _defaultLanguage);

    return videosForCurrentPage?.length > 0
      ? (videosForCurrentPageByLanguage.length > 0 &&
          videosForCurrentPageByLanguage[0]) ||
          videosForCurrentPage[0]
      : null;
  }
}

export default LegacyCourseStore;

export const courseContext = createContext(null);
