import { get } from 'axios';
import { SubscriptionCode } from 'types';
import { SUBSCRIPTION_API } from '../config/constants';

export const getSubscriptions = async () => {
  try {
    const response = await get(SUBSCRIPTION_API);
    return response.data?.items || [];
  } catch (error) {
    throw error;
  }
};

export const isFreeTierSubscriber = (subscription) => {
  return subscription === SubscriptionCode.FreeTier;
};

export const isLS020Subscriber = (subscription) => {
  return subscription === SubscriptionCode.RHLSTrial;
};

export const isLimitedTrialSubscriber = (subscription) => {
  return subscription === SubscriptionCode.LimitedTrial;
};

export const isBasicSubscriber = (subscription) => {
  return subscription === SubscriptionCode.Basic;
};

export const isStandardSubscriber = (subscription) => {
  return subscription === SubscriptionCode.Standard;
};

export const isPremiumSubscriber = (subscription) => {
  return (
    subscription === SubscriptionCode.Premium ||
    isLimitedTrialSubscriber(subscription)
  );
};

export const isRHUSubscriber = (subscription) => {
  return subscription === SubscriptionCode.RedHatUniversity;
};

export const isLS300Subscriber = (subscription) => {
  return subscription === SubscriptionCode.DeveloperTier;
};

export const isLS101Subscriber = (subscription) => {
  return subscription === SubscriptionCode.NewFreeTier;
};

export const isWD200Subscriber = (subscription) => {
  return subscription === SubscriptionCode.WorkForceDevelopmentTier;
};

export const isXS001Subscriber = (subscription) => {
  return subscription === SubscriptionCode.SkillsBuild;
};

export default getSubscriptions;
