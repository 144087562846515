import { get } from 'axios';
import { RHLC_GROUPHUB_MESSAGES } from '../config/constants';

export const getGroupMessages = async (code, retry = false) => {
  const url = `${RHLC_GROUPHUB_MESSAGES}/${code}/grouphub_messages`;
  const notFoundResponse = 'Course is not enabled';
  try {
    const response = await get(url);
    if (response.status === 404) {
      return notFoundResponse;
    }
    return response?.data;
  } catch (error) {
    if (retry) {
      const response = await get(url);
      if (response.status === 404) {
        return notFoundResponse;
      }
      return response?.data;
    }
    throw error;
  }
};

export default getGroupMessages;
