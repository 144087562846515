declare global {
  interface Window {
    AwsWafCaptcha: any;
  }
}

// eslint-disable-next-line import/prefer-default-export
export enum Modality {
  Course = 'course',
  Lab = 'lab',
  VideoClassroom = 'video_classroom',
  Exam = 'exam',
  ExpertSeminar = 'expert_seminar',
  PremVTClass = 'prem_vt_class',
  PremVTEnrollment = 'prem_vt_enrollment',
  TechOverview = 'tech_overview',
}

export enum CourseFormat {
  Bundle = 'bundle',
}

export enum CourseType {
  Regular = 'regular',
  LabPlus = 'lab_plus',
  Lesson = 'module',
  HandsOnLab = 'hands_on_lab',
}

export enum OfferingTier {
  RHLSTrial = 'foundation',
}

export enum OfferingSuffix {
  EarlyAccess = 'ea',
}

export enum OfferingVisibility {
  Visible = 'visible', // user can see the offering
  Hidden = 'hidden', // user can't see the offering
  Upgrade = 'upgrade', // user can see the offering but it will be locked. Requires subscription upgrade.
}

export enum OfferingStatus {
  Active = 'active',
  Retired = 'retired',
  EarlyAccess = 'early',
}

export enum OfferingCodePrefix {
  EarlyAccess = 'ea',
}

export enum SubscriptionCode {
  FreeTier = 'ls000',
  NewFreeTier = 'ls001',
  LimitedTrial = 'ls999',
  Basic = 'ls120',
  Standard = 'ls220',
  Premium = 'ls520',
  RedHatUniversity = 'ls810',
  DeveloperTier = 'ls300',
  WorkForceDevelopmentTier = 'wdp200sp',
  SkillsBuild = 'xs001',
  IBMDigitalLearningSubscription = 'ls130c',
  RHLSTrial = 'ls020',

  // Retired:
  Promo = 'ls101',
  SubscriptionForPartners = 'ls105',
}

/**
 * Namespaces also known as vocabulary slugs
 */
export enum VocabularyNamespaces {
  Modalities = 'modalities',
  Countries = 'countries',
  JobRoles = 'job_roles',
  Departments = 'departments',
  Languages = 'languages',
  CertificationPaths = 'certification_paths',
  SkillsPathTypes = 'skills_path_types',
  OfferingCategories = 'offering_categories',
  OfferingProducts = 'offering_products',
  OfferingTracks = 'offering_tracks',
  // related to new taxonomy:
  OfferingLevel = 'offering_level',
  OfferingTaxonomyAudiences = 'offering_taxonomy_audiences',
  OfferingTaxonomyTopics = 'offering_taxonomy_topics',
  OfferingTaxonomyProducts = 'offering_taxonomy_products',
}

export interface VocabularyItem {
  token: string;
  display_name: string;
}

export type LegacyFilterTaxonomy = {
  products?: string[];
  categories?: string[];
  modalities?: string[];
};

export type NewFilterTaxonomy = {
  products?: string[]; // although similar to legacy's products, this field is used for new taxonomies
  audiences: string[];
  topics: string[];
};

export type CatalogEntry = {
  doc_type: string;
  doc_id: string;
  slug: string;
  status: OfferingStatus;
  sku?: string;
  code: string;
  language: string;
  format?: string;
  '@timestamp': string;
  related_certifications: string[];
  visibility: OfferingVisibility;
  modality: Modality;
  course_type?: CourseType;
  tier?: OfferingTier;
  collateral_id: string;
  version: string;
  title: string;
  bundle_reference?: string;
  duration_unit?: string;
  total_duration?: number;
  total_ge?: number;
  total_labs?: number;
  job_roles?: string[];
  translations?: [
    {
      language: string;
      title: string;
    },
  ];
  taxonomies?: NewFilterTaxonomy; // new taxonomies come in dedicated field
  level: number;
} & LegacyFilterTaxonomy; // legacy taxonomies might still be present for older entries

export interface Catalog {
  doc_type: string;
  '@timestamp': string;
  subscription_code: string;
  catalog_entries: CatalogEntry[];
}

export interface Collateral {
  associated_course_slugs?: string[];
  description?: { body: string };
  audience?: { body: string };
  prerequisites?: { body: string };
  lab_plus_courses?: { body: string };
}

export type UserAchievement = {
  badge_image_url: string;
  title: string;
  issued_at: string;
  badge_url: string;
};

export enum CourseRelationType {
  ChildOf = 'child_of',
  ParentOf = 'parent_of',
  Precedes = 'precedes',
  Follows = 'follows',
  IsRelatedTo = 'is_related_to',
}

export type CourseRelatedContent = {
  relation_type: CourseRelationType;
  code: string;
  version: string;
};

export type CourseMetadata = {
  doc_id: string;
  doc_type: string;
  '@timestamp': string;
  code: string;
  version: string;
  format: string;
  product: string;
  total_video_duration: number;
  skills: string[];
  certifications: string[];
  related_contents: CourseRelatedContent[];
  translations: string[];
  total_duration: number;
  total_labs: number;
  total_ge: number;
  total_quiz: number;
  description: Record<string, string>;
  objectives: Record<string, string[]>;
  audience: Record<string, string[]>;
  prerequisites: Record<string, string[]>;
  views: unknown[];
  duration_unit?: string; // any value from moment-duration-format, like 'minutes', 'hours', etc. Plural and singular are supported.
  taxonomies?: NewFilterTaxonomy; // new taxonomies come in dedicated field
  level: number;
} & LegacyFilterTaxonomy;

export type FeatureFlag = {
  active: boolean;
  name: string;
  super_roles?: string[];
  super_subscriptions?: string[];
  super_users?: string[];
};

export type FeatureSection = {
  section: string;
  flags: FeatureFlag[];
};

export type LearningPath = {
  target_subscriptions: string[];
  doc_type: string;
  archived: boolean;
  code: string;
  description: {
    body: string;
    content_type: string;
  };
  language: string;
  title: string;
  prerequisites: {
    body: string;
    content_type: string;
  };
  '@timestamp': string;
  last_user_activity?: string;
  job_roles?: string[]; // unsure
  curriculum: string;
  joined: boolean;
  lp_elements: {
    user_progress: number;
    code: string;
    title?: string;
    total_progress?: number;
  }[];
  products: string[];
  user_progress: number;
  path_type: string;
  categories: string[];
  translations: {
    language: string;
    title: string;
  }[];
  taxonomies?: NewFilterTaxonomy; // new taxonomies come in dedicated field
  levels: number[];
};

export type OrganizationManager = {
  state: string;
  reportees: number;
  id: string;
  username?: string;
  doc_type: 'user';
  domain?: string;
  uuid?: string;
  preferred_name?: string;
  '@timestamp'?: string;
  group_set?: string[];
};

export type OrganizationAdmin = {
  username: string;
  doc_type: 'user';
  domain: string;
  uuid: string;
  '@timestamp': string;
  group_set?: string[];
  preferred_name?: string;
  default_email?: string;
  emails?: string[];
};

export type Organization = {
  doc_type: 'organization';
  managers?: OrganizationManager[];
  name: string;
  admin_set?: OrganizationAdmin[];
  order_number_set?: string[];
  '@timestamp': string;
  manager_set?: OrganizationManager[];
  doc_id: string;
  id?: string;
  is_pending?: boolean;
};

export type UserEnrollmentInfo = {
  username: string;
  doc_type: 'user_enrollment_info';
  last_page_accessed: string;
  '@timestamp': string;
  _id: string;
  doc_id: string;
  offering_slug: string;
};
