import React from 'react';
import { useFeature } from '@ole-ui/ole-ui-components';
import CertificateOfAttendanceButton from 'components/CertificateOfAttendance/Button';
import EBookDownloader from 'components/EBookDownloader';
import { withErrorBoundary } from 'react-error-boundary';
import { observer } from 'mobx-react';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import useMobxStores from 'hooks/useMobxStores';

import CourseStore from './store';

type Props = {
  courseStore: CourseStore;
  courseSlug: string;
};

const CourseToolbar = ({ courseSlug, courseStore }: Props) => {
  const { userStore } = useMobxStores();

  const {
    loadedInitialData,
    courseCode,
    courseVersion,
    canShowCertAttendanceButton,
    legacyAttendanceTimestamp,
    isFreeToolbar,
    modality,
    isLesson,
  } = courseStore;

  const isEbookDownloaderEnabled = useFeature({
    section: 'course',
    flagName: 'pdf_ebook_button_active',
    defaultFlagValue: false,
  });
  const canShowEbookDownloader = !isLesson && isEbookDownloaderEnabled;

  if (!loadedInitialData) {
    return null;
  }

  const paidToolbar = !isFreeToolbar ? (
    <>
      {/* ebook downloader */}
      {canShowEbookDownloader && (
        <EBookDownloader
          modality={modality}
          course={{
            code: courseCode,
            version: courseVersion,
          }}
          user={userStore.user}
          data-analytics-id="ebook-download-btn-ole-lp"
        />
      )}
    </>
  ) : null;

  return (
    <div className="hud-course-view__toolbar">
      <div className="hud-course-view__toolbar-left">
        {canShowCertAttendanceButton ? (
          <CertificateOfAttendanceButton
            courseSlug={courseSlug}
            timestamp={legacyAttendanceTimestamp}
          />
        ) : null}
      </div>
      <div className="hud-course-view__toolbar-right">{paidToolbar}</div>
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseToolbar),
  ComponentError,
  handleError,
);
