import { observable, action, computed } from 'mobx';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { MINIMUM_COURSE_COMPLETION_PERCENTAGE } from 'config/constants';
import { extractFromSlug } from 'helpers/utils';
import { ProgressByCourse } from 'types/progress';
import {
  getProgress,
  setCourseProgress,
  setCourseProgressExternalSubscription,
  getReporteesProgress,
  updateVideoProgress,
} from '../services/ProgressService';

class ProgressStore {
  @observable progressDict: Record<string, ProgressByCourse> = {};

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action async getProgress() {
    const progress = await getProgress();

    this.rootStore.classesStore.progress = progress;
    this.progressDict = progress;
  }

  // eslint-disable-next-line class-methods-use-this
  @action async getReporteesProgress(organizationId, courseType) {
    const progressData = await getReporteesProgress(organizationId, courseType);
    return progressData;
  }

  @computed get lastAccessProgress() {
    const sortedProgress = sortBy(
      Object.values(this.progressDict),
      '@timestamp',
    ).reverse();

    return sortedProgress?.length > 0 ? sortedProgress[0] : {};
  }

  @computed get coursesInProgress() {
    const courseEntries = Object.entries(this.progressDict);
    if (courseEntries.length) {
      return courseEntries.map(([, progress]) => progress);
    }
    return [];
  }

  @computed get progressByCourse(): ProgressByCourse[] {
    const progressDictByCourseCode = Object.entries(this.progressDict).reduce(
      (dict, [slug, progress]) => {
        const { code: courseCode } = extractFromSlug(slug);
        if (
          !dict[courseCode] ||
          progress['@timestamp'] > dict[courseCode]['@timestamp']
        ) {
          // eslint-disable-next-line no-param-reassign
          dict[courseCode] = progress;
        }
        return dict;
      },
      {},
    );

    const progressArray: ProgressByCourse[] = Object.values(
      progressDictByCourseCode,
    );

    return sortBy(progressArray, '@timestamp').reverse();
  }

  @action getProgressForCourse(courseSlug: string) {
    const allProgress = this.progressByCourse;
    const courseProgress = allProgress.find(
      (progress) => progress?.course_slug === courseSlug,
    );

    return courseProgress;
  }

  @action clearProgress() {
    this.progressDict = {};
  }

  @action setCourseProgress = async (courseSlug: string, pageUUID: string) => {
    const progress: { rv?: ProgressByCourse } = await setCourseProgress(
      courseSlug,
      pageUUID,
    );

    this.progressDict[courseSlug] = progress.rv;
    if (this.rootStore.classesStore.progress[courseSlug] && progress.rv) {
      this.rootStore.classesStore.progress[courseSlug].total_progress =
        progress.rv.total_progress;
      this.rootStore.classesStore.progress[courseSlug].total_score =
        progress.rv.total_score;
    }
    return progress;
  };

  @action updateVideoProgress = async (
    courseSlug: string,
    progress: any,
    currentVideo: string,
    timestamp: number,
  ) => {
    try {
      const progressDocument: ProgressByCourse = await updateVideoProgress(
        courseSlug,
        progress,
        currentVideo,
        timestamp,
      );
      this.progressDict[courseSlug] = progressDocument;
      if (
        this.rootStore.classesStore.progress[courseSlug] &&
        progressDocument
      ) {
        this.rootStore.classesStore.progress[courseSlug].total_progress =
          progressDocument.total_progress;
        this.rootStore.classesStore.progress[courseSlug].total_score =
          progressDocument.total_score;
      }
    } catch (err) {
      console.error(
        'Failed to update the progress of this video or playlist:',
        err,
      );
    }
  };

  // eslint-disable-next-line class-methods-use-this
  @action didJustEnrolledInCourse = (courseProgress: ProgressByCourse) => {
    const { thresholds } = courseProgress;
    const timestamp = courseProgress['@timestamp'];
    if (thresholds) {
      const isJustEnrolled = thresholds.some(
        (threshold) =>
          threshold.level === 'start' && timestamp === threshold.when,
      );

      return isJustEnrolled;
    }

    return false;
  };

  // eslint-disable-next-line class-methods-use-this
  @action didJustFinishedCourse = (courseProgress: ProgressByCourse) => {
    const { thresholds } = courseProgress;
    const timestamp = courseProgress['@timestamp'];
    if (!thresholds) {
      return false;
    }
    const isFinished = thresholds.some(
      (threshold) =>
        threshold.level === 'three_quarter' && timestamp === threshold.when,
    );

    return isFinished;
  };

  // eslint-disable-next-line class-methods-use-this
  @action setCourseProgressExternalSubscription = async (
    courseCode: string,
    currentProgress: unknown,
    subscription: string,
  ) => {
    try {
      const progress = await setCourseProgressExternalSubscription(
        courseCode,
        currentProgress,
        subscription,
      );

      return progress;
    } catch (err) {
      console.error(err);
      throw new Error(
        err.response?.data?.error ||
          `Couldn't update progress for external subscriber`,
      );
    }
  };

  // eslint-disable-next-line class-methods-use-this
  @action isChapterEnabled = (tag: string) =>
    ![1, 2].includes(parseInt(tag.replace('ch', ''), 10));

  shouldShowLegacyCertificateOfAttendance(
    progress = 0,
    progressCompletionTime = null,
  ) {
    if (progress < MINIMUM_COURSE_COMPLETION_PERCENTAGE) {
      return false;
    }

    const CredlyBadgeReleaseDate =
      this.rootStore.configStore.config.CREDLY_BADGE_RELEASE_DATE;
    if (!CredlyBadgeReleaseDate) {
      return true;
    }

    if (!progressCompletionTime) {
      return false;
    }

    const courseCompletionTimeUtc = moment.utc(progressCompletionTime);
    const CredlyBadgeReleaseDateUtc = moment.utc(CredlyBadgeReleaseDate);

    if (courseCompletionTimeUtc.isSameOrAfter(CredlyBadgeReleaseDateUtc)) {
      return false;
    }

    return true;
  }
}

export default ProgressStore;
