/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { action, computed, observable, when } from 'mobx';
import Fuse from 'fuse.js';
import merge from 'deepmerge';
import flatMap from 'lodash/flatMap';
import intersectionBy from 'lodash/intersectionBy';
import { LearningPath, VocabularyNamespaces } from 'types';
import {
  getLearningPaths,
  joinLearningPath,
  unjoinLearningPath,
  deleteCustomSkillPath,
} from 'services/LearningPathService';

import { getLearningPathsForOrgLeader } from '../services/ManagementReportingService';
import CatalogStore, { compareByLevelAsc } from './Catalog';
import { CatalogFilterState } from './Catalog/types';
import { TAXONOMIES_PRIORITIES } from './Catalog/config';
import { prioritizeEntryField } from './Catalog/utils';

type FilterKeys =
  | 'pathTypes'
  | 'categories'
  | 'products'
  | 'roles'
  | 'taxonomyProducts'
  | 'taxonomyAudiences'
  | 'taxonomyTopics'
  | 'levels';

type LearningPathFilters = {
  [K in FilterKeys]?: CatalogFilterState[];
};

class LearningPathsStore {
  rootStore;

  @observable learningPaths: Array<LearningPath> = [];

  @observable filters: LearningPathFilters = {
    categories: [],
    products: [],
    roles: [],
    pathTypes: [],
    // new taxonomies:
    taxonomyProducts: [],
    taxonomyAudiences: [],
    taxonomyTopics: [],
    levels: [],
  };

  @observable expandedFilterGroups = new Set();

  @observable _filteredEntries: Array<LearningPath> = [];

  @observable searchResults: Array<LearningPath> | null = null;

  @observable.ref courseCollateral = [];

  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable loading = false;

  @observable loaded = false;

  @observable deletePathModal = {
    show: false,
    mode: 'confirm',
    isDeleting: false,
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action async init(i18n, vocabularyStore, userStore, force = false) {
    when(
      () => userStore.didFetchSubscription,
      async () => {
        let entries = [];
        if (userStore.hasActiveSubscription) {
          entries = await this.getLearningPaths(force);
        } else if (userStore.isOrganizationLeader) {
          entries = await this.getLearningPathsForOrgLeaders(force);
        }

        if (entries) {
          this.getFilterInfo(i18n, vocabularyStore);
        }
      },
    );
  }

  @action searchEntries = (string: string) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'title',
        'description.body',
        'prerequisites.body',
        'lp_elements.code',
        'lp_elements.title',
        'code',
        'categories',
      ],
    };

    if (!string.length) {
      this.searchResults = this.filteredEntries;
      return;
    }

    const fuse = new Fuse(this.filteredEntries, options);
    this.searchResults = fuse.search(string);
  };

  @computed get query() {
    return this.rootStore.searchStore.query;
  }

  @computed get totalPages(): number {
    return Math.ceil(this.filteredEntries.length / this.entriesPerPage) || 1;
  }

  @computed get currentPage(): number {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get filterKeys(): Array<string> {
    return Object.keys(this.filters);
  }

  @computed get entries(): Array<LearningPath> {
    // Return a unique array of learning paths entries, preferring the one with
    // the latest user activity
    let uniqueArray: Array<LearningPath> = Object.values(
      this.learningPaths.reduce((array, entry) => {
        if (
          !array[entry.last_user_activity] ||
          +entry.last_user_activity > +array[entry.code].last_user_activity
        ) {
          array[entry.code] = entry;
        }

        return array;
      }, {}),
    );

    uniqueArray = uniqueArray.map(
      (entry: LearningPath) =>
        merge(entry, {
          translations: [
            {
              language: 'en-US',
              title: entry.title,
            },
          ],
        }) as LearningPath,
    );

    return uniqueArray;
  }

  set entries(entries: Array<LearningPath>) {
    this.learningPaths = entries;
  }

  @computed get filteredEntries() {
    const checkedFiltersLength = flatMap(
      this.filterKeys.map((key) =>
        this.filters[key].filter((filter) => filter.checked),
      ),
    ).length;

    return checkedFiltersLength || this.query
      ? this._filteredEntries
      : this.entries;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;

    return this.filteredEntries.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  flattenFilters(key) {
    return this.filters[key]
      .filter((item) => item.checked)
      .map((item) => item.key);
  }

  @action filterEntries = () => {
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );
    // TODO: new taxonomies
    const filteredByCategories = this.entries.filter((entry) => {
      const categories = this.flattenFilters('categories');
      return entry?.categories?.some((item) => categories.includes(item));
    });

    const filteredByProducts = this.entries.filter((entry) => {
      const products = this.flattenFilters('products');
      return entry?.products?.some((item) => products.includes(item));
    });

    const filteredByRoles = this.entries.filter((entry) => {
      const roles = this.flattenFilters('roles');
      const { job_roles: jobRoles } = entry;
      return jobRoles?.some((item) => roles.includes(item));
    });

    const filteredByPathTypes = this.entries.filter((entry) => {
      const pathTypes = this.flattenFilters('pathTypes');
      const { path_type: pathType } = entry;

      return pathTypes?.some((item) => item === pathType);
    });

    const filters = [];
    if (filteredByCategories.length) filters.push(filteredByCategories);
    if (filteredByProducts.length) filters.push(filteredByProducts);
    if (filteredByRoles.length) filters.push(filteredByRoles);
    if (filteredByPathTypes.length) filters.push(filteredByPathTypes);

    if (newTaxonomiesEnabled) {
      const filteredByTaxonomyTopics = this.entries.filter((entry) => {
        const entryTaxonomyTopics = prioritizeEntryField(
          entry,
          TAXONOMIES_PRIORITIES.new.topics,
          true,
        );
        const topics = this.flattenFilters('taxonomyTopics');
        return entryTaxonomyTopics?.some((item) => topics.includes(item));
      });

      const filteredByTaxonomyAudiences = this.entries.filter((entry) => {
        const entryTaxonomyAudiences = prioritizeEntryField(
          entry,
          TAXONOMIES_PRIORITIES.new.audiences,
          true,
        );
        const audiences = this.flattenFilters('taxonomyAudiences');
        return entryTaxonomyAudiences?.some((item) => audiences.includes(item));
      });

      const filteredByTaxonomyProducts = this.entries.filter((entry) => {
        const entryTaxonomyProducts = prioritizeEntryField(
          entry,
          TAXONOMIES_PRIORITIES.new.products,
          true,
        );
        const taxonomyProducts = this.flattenFilters('taxonomyProducts');
        return entryTaxonomyProducts?.some((item) =>
          taxonomyProducts.includes(item),
        );
      });

      // if any element in the Learning Path has a levels that matches the filter, then include the LearningPath:
      const filteredByLevels = this.entries.filter((entry) => {
        const entryLevels = prioritizeEntryField(entry, ['levels'], true);

        const levels = this.flattenFilters('levels');

        return entryLevels?.some((entryLevel) =>
          levels.includes(`${entryLevel}`),
        );
      });

      if (filteredByTaxonomyTopics.length)
        filters.push(filteredByTaxonomyTopics);
      if (filteredByTaxonomyAudiences.length)
        filters.push(filteredByTaxonomyAudiences);
      if (filteredByTaxonomyProducts.length)
        filters.push(filteredByTaxonomyProducts);
      if (filteredByLevels.length) filters.push(filteredByLevels);
    }

    this._filteredEntries = intersectionBy(...filters, 'code');

    if (!filters.length) {
      this._filteredEntries = this.entries;
    }

    if (this.query) {
      this.searchEntries(this.query);
      this._filteredEntries = this.searchResults;
    }
  };

  @action getLearningPaths = async (force?: boolean) => {
    if (this.learningPaths.length && !force) {
      return this.learningPaths;
    }
    try {
      this.loading = true;

      const learningPaths = await getLearningPaths();

      if (learningPaths) {
        this.learningPaths = learningPaths;
      }
    } catch {
      this.learningPaths = [];
    } finally {
      this.loading = false;
    }
    return this.learningPaths;
  };

  @action getLearningPathsForOrgLeaders = async (force?: boolean) => {
    if (this.learningPaths.length && !force) {
      return this.learningPaths;
    }
    try {
      this.loading = true;

      const learningPaths = await getLearningPathsForOrgLeader();

      if (learningPaths) {
        this.learningPaths = learningPaths;
      }
    } catch {
      this.learningPaths = [];
    } finally {
      this.loading = false;
    }
    return this.learningPaths;
  };

  // This populates filtero options:
  @action getFilterInfo = async (i18n, vocabularyStore) => {
    const { language } = i18n;
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );
    const { getVocabularyByNamespace } = vocabularyStore;

    const categoryLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.OfferingCategories,
      language,
    );

    this.filters.categories = CatalogStore.renderFilters({
      values: flatMap(this.entries, 'categories'),
      vocabulary: categoryLabels,
    });

    const productLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.OfferingProducts,
      language,
    );

    this.filters.products = CatalogStore.renderFilters({
      values: flatMap(this.entries, 'products'),
      vocabulary: productLabels,
    });

    const roleLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.JobRoles,
      language,
    );

    this.filters.roles = CatalogStore.renderFilters({
      values: flatMap(this.entries, 'job_roles'),
      vocabulary: roleLabels,
    });

    if (newTaxonomiesEnabled) {
      const topicLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyTopics,
        language,
      );

      this.filters.taxonomyTopics = CatalogStore.renderFilters({
        values: this.entries
          .map((entry) =>
            prioritizeEntryField(entry, TAXONOMIES_PRIORITIES.new.topics, true),
          )
          .flat(),
        vocabulary: topicLabels,
      });

      const audienceLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyAudiences,
        language,
      );

      this.filters.taxonomyAudiences = CatalogStore.renderFilters({
        values: this.entries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.audiences,
              true,
            ),
          )
          .flat(),
        vocabulary: audienceLabels,
      });

      const taxonomyProductLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyProducts,
        language,
      );

      this.filters.taxonomyProducts = CatalogStore.renderFilters({
        values: this.entries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.products,
              true,
            ),
          )
          .flat(),
        vocabulary: taxonomyProductLabels,
      });

      const levelLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingLevel,
        language,
      );

      this.filters.levels = CatalogStore.renderFilters({
        values: this.entries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              ['levels'],
              true, // for SkillPaths the levels field is an array containing the levels of each offering
            ),
          )
          .flat(),
        vocabulary: levelLabels,
        compareFn: compareByLevelAsc,
      });
    }

    const pathTypeLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.SkillsPathTypes,
      language,
    );

    this.filters.pathTypes = CatalogStore.renderFilters({
      values: flatMap(this.entries, 'path_type'),
      vocabulary: pathTypeLabels,
    });

    return this.filters;
  };

  clearFilters = (exceptions = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
        }
      });
    });

    this.filterEntries();
  };

  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    this.filterEntries();
  };

  @action toggleFilterGroups = (id: string, expanded?: boolean) =>
    expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get joinedLearningPaths() {
    return this.learningPaths?.filter((path) => path.joined);
  }

  @action joinLearningPath(code: string) {
    joinLearningPath(code).then(() => {
      const index = this.learningPaths.findIndex((p) => p.code === code);
      const pathToUpdate = this.learningPaths[index];
      pathToUpdate.joined = !pathToUpdate.joined;

      this.filterEntries();
    });
  }

  @action unjoinLearningPath(code: string) {
    unjoinLearningPath(code).then(() => {
      const index = this.learningPaths.findIndex((p) => p.code === code);
      const pathToUpdate = this.learningPaths[index];
      pathToUpdate.joined = !pathToUpdate.joined;

      this.filterEntries();
    });
  }

  @action clearPaths() {
    this.learningPaths = [];
  }

  @action async handleDeleteCSK(
    i18n,
    vocabularyStore,
    userStore,
    pathToDelete,
  ) {
    try {
      await deleteCustomSkillPath(pathToDelete.code);

      await this.init(i18n, vocabularyStore, userStore, true);
    } catch {
      throw new Error('Cannot delete a path');
    }
  }
}

export default LearningPathsStore;
