const languages = {
  'cs-CZ': {
    code: '',
    name: 'Czech',
    localName: 'Čeština',
  },
  'de-DE': {
    code: 'de',
    name: 'German',
    localName: 'Deutsch',
  },
  'en-US': {
    code: 'en',
    name: 'English',
    localName: 'English',
  },
  'es-ES': {
    code: 'es',
    name: 'Spanish',
    localName: 'Español',
  },
  'fr-FR': {
    code: 'fr',
    name: 'French',
    localName: 'Français',
  },
  'it-IT': {
    code: 'it',
    name: 'Italian',
    localName: 'Italiano',
  },
  'ja-JP': {
    code: 'ja',
    name: 'Japanese',
    localName: '日本語',
  },
  'ko-KR': {
    code: 'ko',
    name: 'Korean',
    localName: '한국어',
  },
  'pt-BR': {
    code: 'pt-br',
    name: 'Brazilian Portuguese',
    localName: 'Português (BR)',
  },
  'ru-RU': {
    code: 'ru',
    name: 'Russian',
    localName: 'Русский',
  },
  'zh-CN': {
    code: 'zh-cn',
    name: 'Simplfied Chinese',
    localName: '简体中文',
  },
  'zh-TW': {
    code: 'zh-tw',
    name: 'Traditional Chinese',
    localName: '繁體中文',
  },
  'hi-IN': {
    code: 'hi-IN',
    name: 'Hindi',
    localName: 'हिन्दी',
  },
};

const getLanguageInfo = (code) => {
  let lang = {};
  if (languages[code]) {
    Object.keys(languages).forEach((index) => {
      if (index === code) {
        lang = languages[index];
        return lang;
      }
      return lang;
    });
  } else {
    lang.localName = code;
  }
  return lang;
};

export { getLanguageInfo };
export default getLanguageInfo;
