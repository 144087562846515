import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';

import {
  CatalogEntry,
  CourseType,
  Modality,
  OfferingTier,
  VocabularyNamespaces,
} from 'types';
import { CatalogFilterConfiguration, CatalogFilterState } from './types';
import { prioritizeEntryField } from './utils';

// These fields are used to extract the categories and products
// from the catalog entries. The first element is prioritized over the second,
// and so on.
export const TAXONOMIES_PRIORITIES = {
  legacy: {
    categories: ['metadata.categories', 'categories'],
    products: ['metadata.products', 'products'],
  },
  new: {
    topics: ['metadata.taxonomies.topics', 'taxonomies.topics'],
    audiences: ['metadata.taxonomies.audiences', 'taxonomies.audiences'],
    products: ['metadata.taxonomies.products', 'taxonomies.products'],
    level: ['metadata.level', 'level'], // the only one outside of taxonomies
  },
};

export const compareByLevelAsc = (
  a: CatalogFilterState,
  b: CatalogFilterState,
) => parseInt(a.key, 10) - parseInt(b.key, 10);

/**
 * Returns the filter configurations for the catalog.
 * @param includeNewTaxonomies Whether to include the new taxonomies in the filter configurations.
 * @returns The filter configurations.
 */
export const getFilterConfigurations = (
  includeNewTaxonomies: boolean = false,
): CatalogFilterConfiguration[] => {
  // TODO: In the future (rhls 5 maybe) we should consider using this configuration to generate the UI as well.
  const filterConfigurations: CatalogFilterConfiguration[] = [
    {
      key: 'earlyAccess',
      extractFilterValues: (entries) => flatMap(entries, 'status'),
      vocabulary: [{ display_name: 'Early Access', token: 'early' }],
      validateEntry: (entry, checkedEarlyAccess) =>
        checkedEarlyAccess.includes(entry?.status),
    },
    {
      key: 'rhlsTrial',
      extractFilterValues: (entries) =>
        filter(entries, {
          tier: OfferingTier.RHLSTrial,
        }).map((entry: CatalogEntry) => entry.tier),
      vocabulary: [
        { display_name: 'Trial Subscription', token: OfferingTier.RHLSTrial },
      ],
      validateEntry: (entry, checkedRHLSTrial) =>
        checkedRHLSTrial.includes(entry?.tier),
    },
    {
      key: 'categories',
      extractFilterValues: (entries) =>
        entries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.legacy.categories,
              true,
            ),
          )
          .flat(),
      vocabularyNamespace: VocabularyNamespaces.OfferingCategories,
      validateEntry: (entry, checkedCategories) => {
        const entryCategories: string[] = prioritizeEntryField(
          entry,
          TAXONOMIES_PRIORITIES.legacy.categories,
          true,
        );

        const filtered = entryCategories?.filter((item) =>
          checkedCategories.includes(item),
        );

        return entryCategories?.some((item) => filtered.includes(item));
      },
    },
    {
      key: 'languages',
      extractFilterValues: (entries) =>
        flatMap(entries, (key) =>
          key.translations?.map((translation) => translation?.language),
        ),
      vocabularyNamespace: VocabularyNamespaces.Languages,
      validateEntry: (entry, checkedLanguages) => {
        const languageMap = flatMap(entry.translations, 'language');
        const filtered = languageMap?.filter((item) =>
          checkedLanguages.includes(item),
        );
        return languageMap?.some((item) => filtered.includes(item));
      },
    },
    {
      key: 'deliveryFormats',
      extractFilterValues: (entries) =>
        flatMap(entries, (obj: CatalogEntry) => {
          if (obj.course_type && obj.course_type !== CourseType.Regular) {
            // consider labplus as a lesson in this filter:
            if (obj.course_type === CourseType.LabPlus) {
              return CourseType.Lesson;
            }

            // lessons, labplus, etc
            return obj.course_type ? obj.course_type : [];
          }
          return obj.modality;
        }),
      vocabularyNamespace: VocabularyNamespaces.Modalities,
      compareFn: (a: CatalogFilterState, b: CatalogFilterState) => {
        // custom order for modality
        const customOrder = [
          CourseType.Lesson,
          Modality.Course,
          CourseType.LabPlus,
          Modality.Exam,
          Modality.TechOverview,
          Modality.ExpertSeminar,
        ];

        const indexOfA = customOrder.indexOf(a.key as CourseType | Modality);
        const indexOfB = customOrder.indexOf(b.key as CourseType | Modality);

        if (indexOfA === -1) {
          return 1;
        }

        return indexOfA - indexOfB;
      },
      validateEntry: (entry, checkedDeliveryFormats) => {
        let entryModality = '';

        if (
          entry?.course_type === CourseType.Regular ||
          (entry?.modality && !entry?.course_type)
        ) {
          entryModality = entry?.modality;
        }

        let entryCourseType = (entry?.course_type as string) || '';

        if (entry?.course_type === CourseType.LabPlus) {
          entryCourseType = CourseType.Lesson;
        }

        return (
          checkedDeliveryFormats.includes(entryModality) ||
          checkedDeliveryFormats.includes(entryCourseType)
        );
      },
    },
    {
      key: 'products',
      extractFilterValues: (entries) =>
        entries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.legacy.products,
              true,
            ),
          )
          .flat(),
      vocabularyNamespace: VocabularyNamespaces.OfferingProducts,
      validateEntry: (entry, checkedProducts) => {
        const entryProducts: string[] = prioritizeEntryField(
          entry,
          TAXONOMIES_PRIORITIES.legacy.products,
          true,
        );

        const filtered =
          entryProducts?.filter((item) => checkedProducts.includes(item)) || [];

        return entryProducts?.some((item) => filtered.includes(item));
      },
    },
    {
      key: 'roles',
      extractFilterValues: (entries) => flatMap(entries, 'job_roles'),
      vocabularyNamespace: VocabularyNamespaces.JobRoles,
      validateEntry: (entry, checkedRoles) => {
        const { job_roles: jobRoles } = entry;
        const filtered = jobRoles?.filter((item) =>
          checkedRoles.includes(item),
        );
        return jobRoles?.some((item) => filtered.includes(item));
      },
    },
  ];

  if (includeNewTaxonomies) {
    filterConfigurations.push(
      {
        key: 'taxonomyTopics',
        extractFilterValues: (entries) =>
          entries
            .map((entry) =>
              prioritizeEntryField(
                entry,
                TAXONOMIES_PRIORITIES.new.topics,
                true,
              ),
            )
            .flat(),
        vocabularyNamespace: VocabularyNamespaces.OfferingTaxonomyTopics,
        validateEntry: (entry, checkedTopics) => {
          const entryTopics: string[] = prioritizeEntryField(
            entry,
            TAXONOMIES_PRIORITIES.new.topics,
            true,
          );
          const filtered = entryTopics?.filter((item) =>
            checkedTopics.includes(item),
          );

          return entryTopics?.some((item) => filtered.includes(item));
        },
      },
      {
        key: 'taxonomyAudiences',
        extractFilterValues: (entries) =>
          entries
            .map((entry) =>
              prioritizeEntryField(
                entry,
                TAXONOMIES_PRIORITIES.new.audiences,
                true,
              ),
            )
            .flat(),
        vocabularyNamespace: VocabularyNamespaces.OfferingTaxonomyAudiences,
        validateEntry: (entry, checkedAudiences) => {
          const entryAudiences: string[] = prioritizeEntryField(
            entry,
            TAXONOMIES_PRIORITIES.new.audiences,
            true,
          );

          const filtered = entryAudiences?.filter((item) =>
            checkedAudiences.includes(item),
          );

          return entryAudiences?.some((item) => filtered.includes(item));
        },
      },
      {
        key: 'taxonomyProducts',
        extractFilterValues: (entries) =>
          entries
            .map((entry) =>
              prioritizeEntryField(
                entry,
                TAXONOMIES_PRIORITIES.new.products,
                true,
              ),
            )
            .flat(),
        vocabularyNamespace: VocabularyNamespaces.OfferingTaxonomyProducts,
        validateEntry: (entry, checkedTaxonomyProducts) => {
          const entryProducts: string[] = prioritizeEntryField(
            entry,
            TAXONOMIES_PRIORITIES.new.products,
            true,
          );

          const filtered =
            entryProducts?.filter((item) =>
              checkedTaxonomyProducts.includes(item),
            ) || [];

          return entryProducts?.some((item) => filtered.includes(item));
        },
      },
      {
        key: 'level',
        extractFilterValues: (entries) =>
          entries
            .map((entry) =>
              prioritizeEntryField(
                entry,
                TAXONOMIES_PRIORITIES.new.level,
                false,
              ),
            )
            .flat(),
        vocabularyNamespace: VocabularyNamespaces.OfferingLevel,
        compareFn: compareByLevelAsc,
        validateEntry: (entry, checkedLevels) => {
          const entryLevel = prioritizeEntryField(
            entry,
            TAXONOMIES_PRIORITIES.new.level,
            false,
          );

          return checkedLevels.includes(`${entryLevel}`);
        },
      },
    );
  }

  return filterConfigurations;
};

// TODO: unit tests to test each extract, compare, and filter function
