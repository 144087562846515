/**
 * @file Course Catalog filters
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import {
  Checkbox,
  Form,
  FormGroup,
  Panel,
  PanelGroup,
  Button,
} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { KalturaWidget, useFeature } from '@ole-ui/ole-ui-components';
import { storesContext } from 'stores';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import './styles.scss';
import { KALTURA_CONFIGS } from 'config/constants';
import { OfferingStatus, OfferingTier } from 'types';

const CatalogFilters = ({ 'analytics-suffix': analyticsSuffix }) => {
  const { t } = useTranslation();
  const { catalogStore, routerStore, userStore } = useContext(storesContext);
  const newTaxonomiesEnabled = useFeature({
    section: 'catalog',
    flagName: 'new_taxonomies',
    defaultFlagValue: false,
  });

  const canShowRHLSTrialFilter = true; // TODO: Implement this based on user subscription

  const clearFilters = (e, exceptions = []) => {
    catalogStore.clearFilters(exceptions);
  };

  /**
   * This method is used to apply the filter when the user clicks on a filter option
   * @param {*} e onChange event
   * @param {*} selectedValues value(s) selected by the user when clicking the filter. Ex: 'redhat_fuse'
   * @param {*} filterKey key of the filter. Ex: 'taxonomyProducts'. If not provided, the check will be applied to all filters containing the selected value
   */
  const onChange = (e, selectedValues, filterKey) => {
    const { checked } = e.target;

    catalogStore.filterKeys.forEach((key) => {
      if (filterKey && key !== filterKey) return; // If filterKey is provided, only apply to that filter

      const fk = Array.isArray(selectedValues)
        ? selectedValues
        : [selectedValues];

      fk.forEach((selectedValue) => {
        const found = catalogStore.filters[key]?.find(
          (filter) => filter.key === selectedValue,
        );

        if (!found) return;

        found.checked = checked;
      });
    });

    catalogStore.filterEntries();
  };

  const onSingleOptionFilterChange = ({
    event,
    filterKey,
    clearOtherFilters = true,
    clearValuesExcept = [],
  }) => {
    const { checked } = event.target;

    if (clearOtherFilters && checked) {
      // Clear all other filters
      clearFilters(event, clearValuesExcept);
    }

    onChange(event, filterKey);
  };

  const isChecked = (filterKey, key) =>
    catalogStore.filters[filterKey].find((filter) => filter.key === key)
      ?.checked;

  const isExpanded = (id) => catalogStore.expandedFilterGroups?.has(id);

  const renderFilterGroup = (id, key, title) => {
    if (!catalogStore.filters[key]?.length) {
      return null;
    }

    return (
      <Panel
        eventKey={id}
        className="filter-panel"
        onToggle={(expanded) => {
          catalogStore.toggleFilterGroups(id, expanded);
        }}
        data-analytics-id={`${key}-filt-${analyticsSuffix}`}
      >
        <Panel.Toggle componentClass="div" className="filter-panel-toggle">
          <Panel.Heading
            componentClass="header"
            className="filter-panel-heading"
          >
            <Panel.Title className="filter-panel-heading-title">
              {title}
            </Panel.Title>
            <div className="filter-panel-heading-icon">
              <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
                {isExpanded(id) ? t('Collapse') : t('Expand')}
              </SVG>
            </div>
          </Panel.Heading>
        </Panel.Toggle>
        <Panel.Body collapsible className="filter-panel-body">
          <Form>
            <FormGroup>
              {catalogStore.filters[key].map((item) => (
                <div
                  key={item?.key}
                  className="filter-control-wrapper"
                  data-analytics-id={`container-${item?.key}-${key}-filt-${analyticsSuffix}`}
                >
                  {item?.value !== 'Video Classroom' && (
                    <React.Fragment>
                      <Checkbox
                        checked={isChecked(key, item?.key)}
                        name={item?.key}
                        className="filter-control"
                        onChange={(e) => {
                          onChange(e, item?.key, key);
                        }}
                        data-analytics-id={`${item?.key}-${key}-filt-${analyticsSuffix}`}
                      >
                        {item?.value}
                      </Checkbox>
                      <small className="filter-control-badge">
                        {item?.count}
                      </small>
                    </React.Fragment>
                  )}
                </div>
              ))}
            </FormGroup>
          </Form>
        </Panel.Body>
      </Panel>
    );
  };

  const renderSingleOptionFilter = ({
    filterKey,
    analyticsIdPrefix,
    theme = '',
    clearOtherFilters = true,
    clearValuesExcept = [],
  }) => {
    if (!catalogStore.filters[filterKey]?.length) {
      return null;
    }

    const headerClassnames = cn('filter-panel-heading', {
      [`filter-panel-heading--${theme}`]: Boolean(theme),
    });
    return (
      <Panel className="filter-panel single-option-filter">
        <Panel.Heading componentClass="header" className={headerClassnames}>
          <Panel.Title className="filter-panel-heading-title">
            <Form>
              <FormGroup>
                {catalogStore.filters[filterKey].map((item) => (
                  <div key={item?.key} className="filter-control-wrapper">
                    <Checkbox
                      data-analytics-id={`${analyticsIdPrefix}-chckbox-filt-${analyticsSuffix}`}
                      checked={isChecked(filterKey, item?.key)}
                      name={item?.key}
                      className="filter-control"
                      onChange={(e) => {
                        onSingleOptionFilterChange({
                          event: e,
                          filterKey: item?.key,
                          clearOtherFilters,
                          clearValuesExcept,
                        });
                      }}
                    >
                      {t(item?.value)}
                    </Checkbox>
                    <small className="filter-control-badge">
                      {item?.count}
                    </small>
                  </div>
                ))}
              </FormGroup>
            </Form>
          </Panel.Title>
        </Panel.Heading>
      </Panel>
    );
  };

  /**
   * This groups up filter options with the same label
   */
  const renderClusterTypeFilter = (id, key, title) => {
    if (!catalogStore.filters[key]?.length) {
      return null;
    }

    // TODO? Memoize this computation...
    const groupedFilterOptions = groupBy(catalogStore.filters[key], 'value');
    return (
      <Panel
        eventKey={id}
        className="filter-panel"
        onToggle={(expanded) => {
          catalogStore.toggleFilterGroups(id, expanded);
        }}
        data-analytics-id={`${key}-filt-${analyticsSuffix}`}
      >
        <Panel.Toggle componentClass="div" className="filter-panel-toggle">
          <Panel.Heading
            componentClass="header"
            className="filter-panel-heading"
          >
            <Panel.Title className="filter-panel-heading-title">
              {title}
            </Panel.Title>
            <div className="filter-panel-heading-icon">
              <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
                {isExpanded(id) ? t('Collapse') : t('Expand')}
              </SVG>
            </div>
          </Panel.Heading>
        </Panel.Toggle>
        <Panel.Body collapsible className="filter-panel-body">
          <Form>
            <FormGroup>
              {Object.keys(groupedFilterOptions).map((groupKey) => {
                const groupItems = groupedFilterOptions[groupKey];
                const groupSummary = reduce(
                  groupItems,
                  (acc, element) => {
                    return {
                      ...acc,
                      totalCount: acc.totalCount + element.count,
                      keyList: [...acc.keyList, element.key],
                      key: element.key, // does not matter much
                      label: element.value, // all items have same label
                    };
                  },
                  { totalCount: 0, keyList: [] },
                );
                return (
                  <div
                    key={groupSummary?.key}
                    className="filter-control-wrapper"
                    data-analytics-id={`container-${groupSummary?.key}-${key}-filt-${analyticsSuffix}`}
                  >
                    <Checkbox
                      checked={isChecked(key, groupSummary?.key)}
                      name={groupSummary?.key}
                      className="filter-control"
                      onChange={(e) => {
                        // For example, when you click "beginner", all values mapping to this label
                        // will be applied to filter, like 0, 1, 2 and 3.
                        onChange(e, groupSummary?.keyList, key);
                      }}
                      data-analytics-id={`${groupSummary?.key}-${key}-filt-${analyticsSuffix}`}
                    >
                      {groupSummary?.label}
                    </Checkbox>
                    <small className="filter-control-badge">
                      {groupSummary.totalCount}
                    </small>
                  </div>
                );
              })}
            </FormGroup>
          </Form>
        </Panel.Body>
      </Panel>
    );
  };

  const renderCancelCustomSkillPathButton = () => {
    return (
      <Button
        key="lp-csk-button"
        bsStyle="primary"
        className="btn-block"
        onClick={() => {
          routerStore.navigate('skills-paths');
        }}
        data-analytics-id={`cancel-filt-${analyticsSuffix}`}
      >
        {t('Cancel')}
      </Button>
    );
  };

  const renderLegacyTaxonomyFilters = () => {
    return (
      <React.Fragment>
        {renderFilterGroup(3, 'products', t('Products'))}
        {renderFilterGroup(4, 'categories', t('Categories'))}
      </React.Fragment>
    );
  };

  const renderNewTaxonomyFilters = () => {
    return (
      <React.Fragment>
        {renderFilterGroup(3, 'taxonomyProducts', t('Products'))}
        {renderFilterGroup(4, 'taxonomyTopics', t('Topics'))}
        {renderFilterGroup(5, 'taxonomyAudiences', t('Audiences'))}
        {renderClusterTypeFilter(6, 'level', t('Levels'))}
      </React.Fragment>
    );
  };

  return (
    <section className="catalog-filters">
      <header className="filter-group-heading">{t('Filter by')}</header>
      <header
        className="filter-clear"
        role="button"
        tabIndex="0"
        onClick={(e) => {
          clearFilters(e);
        }}
        data-analytics-id={`filter-cancel-btn-${analyticsSuffix}`}
      >
        {t('Clear all')}
      </header>
      <PanelGroup id="filter-panel">
        {renderSingleOptionFilter({
          filterKey: 'earlyAccess',
          analyticsIdPrefix: 'ea',
          clearValuesExcept: [OfferingStatus.EarlyAccess],
        })}
        {canShowRHLSTrialFilter &&
          renderSingleOptionFilter({
            filterKey: 'rhlsTrial',
            analyticsIdPrefix: 'rhlstrial',
            theme: 'dark',
            clearValuesExcept: [OfferingTier.RHLSTrial],
          })}
        {renderFilterGroup(1, 'deliveryFormats', t('Delivery Formats'))}
        {renderFilterGroup(2, 'roles', t('Job Roles'))}
        {newTaxonomiesEnabled
          ? renderNewTaxonomyFilters()
          : renderLegacyTaxonomyFilters()}
        {renderFilterGroup(6, 'languages', t('Languages'))}
        {(userStore.currentSubscriptionCode ||
          userStore.isOrganizationAdmin ||
          userStore.isOrganizationManager) &&
          (routerStore.route?.name === 'custom-skill-path' ||
            routerStore.route?.name === 'custom-skill-path-edit') && (
            <div className="custom-skill-path">
              {renderCancelCustomSkillPathButton()}
            </div>
          )}
      </PanelGroup>
      {userStore.isFreeTierSubscriber && (
        <div
          className="premVidFreeTierConatiner"
          data-analytics-id="prem-vid-free-tier-catalog-lp"
        >
          <KalturaWidget
            className="expert-extras-player"
            partnerId={KALTURA_CONFIGS.partnerId}
            playerId={KALTURA_CONFIGS.premiumInFreeTrial.uiConfId}
            entryId={KALTURA_CONFIGS.premiumInFreeTrial.entryId}
            isPlaylist={false}
          />
        </div>
      )}
    </section>
  );
};

CatalogFilters.propTypes = {
  'analytics-suffix': PropTypes.string,
};

CatalogFilters.defaultProps = {
  'analytics-suffix': 'cat-lp',
};

export default observer(CatalogFilters);
