import { action, computed, observable, toJS } from 'mobx';

import flatMap from 'lodash/flatMap';
import intersectionBy from 'lodash/intersectionBy';
import moment from 'moment';
import * as merge from 'deepmerge';
import { VocabularyNamespaces } from 'types';
import { extractFromSlug, toSlug } from 'helpers/utils';
import { PREMIUM_VT_SESSION_STATES } from '../config/constants';
import CatalogStore, { compareByLevelAsc } from './Catalog';
import { TAXONOMIES_PRIORITIES } from './Catalog/config';
import { prioritizeEntryField } from './Catalog/utils';

const hasAnyFilterActive = (filters, filterKeys) => {
  return Boolean(
    flatMap(
      filterKeys.map((key) => filters[key].filter((filter) => filter.checked)),
    ).length,
  );
};

// TODO: convert this file to TS like CatalogStore
class PremiumSessionFilterStore {
  @observable loaded = false;

  @observable filters = {
    products: [],
    categories: [],
    instructors: [],
    languages: [],
    daterange: [
      {
        key: 'daterange1',
        value: {
          from: undefined,
          to: undefined,
        },
        count: 0,
        checked: false,
      },
    ],
    timerange: [
      {
        key: 'timerange1',
        value: {
          from: undefined,
          to: undefined,
        },
        count: 0,
        checked: false,
      },
    ],
    fullsessions: [
      {
        key: 'fullsessions',
        count: 0,
        checked: false,
      },
    ],
    // new taxonomies:
    taxonomyProducts: [],
    taxonomyAudiences: [],
    taxonomyTopics: [],
    level: [],
  };

  @observable expandedFilterGroups = new Set();

  @observable _filteredScheduledSessionEntries = [];

  @observable _filteredSessionContent = [];

  @observable filterQueryFunc = () => {};

  constructor(premiumSessionsStore, catalogStore, rootStore) {
    this.premiumSessionsStore = premiumSessionsStore;
    this.catalogStore = catalogStore;
    this.rootStore = rootStore;
  }

  @computed get filterKeys() {
    return Object.keys(this.filters);
  }

  @computed get filteredEntries() {
    return hasAnyFilterActive(this.filters, this.filterKeys)
      ? this._filteredScheduledSessionEntries
      : this.premiumSessionsStore.premiumSessions;
  }

  @computed get filteredSessionContentEntries() {
    return hasAnyFilterActive(this.filters, this.filterKeys)
      ? this._filteredSessionContent
      : this.premiumSessionsStore.premiumContentWithScheduledSessions;
  }

  @action flattenFilters = (key) => {
    return this.filters[key]
      .filter((item) => item.checked)
      .map((item) => item.key);
  };

  @action filterByDateTimeRanges = (_filteredEntries) => {
    const range = this.filters.daterange[0]?.value;

    let startDateRange =
      range && range.from ? this.getStartDate(range.from) : moment();
    startDateRange = this.getStartDate(startDateRange);

    let endDateRange = range && range.to ? moment(range.to) : moment();
    endDateRange = this.getEndDate(endDateRange);

    const timerange = this.filters.timerange[0]?.value;
    const startTimeRange =
      timerange && timerange.from
        ? moment().set({
            hour: moment(timerange.from).get('hour'),
            minute: moment(timerange.from).get('minute'),
            second: moment(timerange.from).get('second'),
          })
        : this.getStartDate(moment());

    const endTimeRange =
      timerange && timerange.to
        ? moment().set({
            hour: moment(timerange.to).get('hour'),
            minute: moment(timerange.to).get('minute'),
            second: moment(timerange.to).get('second'),
          })
        : this.getEndDate(moment());

    const filteredByRanges =
      this.filters.daterange[0]?.checked || this.filters.timerange[0]?.checked
        ? _filteredEntries.filter((entry) => {
            const isBetweenDateRange = this.filters.daterange[0].checked
              ? this.isBetweenDateTimeRange(
                  entry.start_time,
                  startDateRange,
                  endDateRange,
                  'day',
                )
              : false;

            if (this.filters.timerange[0]?.checked) {
              const sessionStartTime = moment().set({
                hour: moment(entry.start_time).get('hour'),
                minute: moment(entry.start_time).get('minute'),
                second: moment(entry.start_time).get('second'),
              });

              const isBetweenTimeRange = this.isBetweenDateTimeRange(
                sessionStartTime,
                startTimeRange,
                endTimeRange,
                'hours',
              );

              return this.filters.daterange[0]?.checked
                ? isBetweenDateRange && isBetweenTimeRange
                : isBetweenTimeRange;
            }
            return isBetweenDateRange;
          })
        : _filteredEntries;
    return filteredByRanges;
  };

  getStartDate = (startDate) => {
    return moment(startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
  };

  getEndDate = (endDate) => {
    return moment(endDate).set({
      hour: 23,
      minute: 59,
      second: 59,
    });
  };

  isBetweenDateTimeRange = (dateToCompare, start, end, unit) => {
    return !(
      moment(dateToCompare).isBefore(start, unit) ||
      moment(dateToCompare).isAfter(end, unit)
    );
  };

  @action filterEntries = () => {
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );
    const _filtered = this.premiumSessionsStore.premiumSessions.filter((s) => {
      return this.filterQueryFunc(s);
    });
    // filter instructors
    const sessionsFilteredByInstructors = _filtered.filter((entry) => {
      const instructors = this.flattenFilters('instructors');
      const filtered = entry.instructors?.filter((item) =>
        instructors.includes(item.username),
      );
      return entry?.instructors?.some((item) => filtered.includes(item));
    });

    // filter insturctor's languages.
    const filteredLanguages = _filtered.filter((entry) => {
      const languages = this.flattenFilters('languages');

      // eslint-disable-next-line camelcase
      return languages.includes(entry?.instruction_language);
    });

    const filters = [];
    if (sessionsFilteredByInstructors.length)
      filters.push(sessionsFilteredByInstructors);
    if (filteredLanguages.length) filters.push(filteredLanguages);

    let _filteredEntries = intersectionBy(...filters, 'doc_id');

    if (!filters.length) {
      _filteredEntries = this.premiumSessionsStore.premiumSessions;
    }

    _filteredEntries = this.hideFullSessions
      ? _filtered.filter((entry) => {
          // return sessions with available seats.
          return toJS(entry.seats_available) > 0;
        })
      : _filteredEntries;

    // Filter by date and time range
    this._filteredScheduledSessionEntries =
      this.filterByDateTimeRanges(_filteredEntries);

    // filter categories
    const categories = this.flattenFilters('categories');
    const filteredPremiumCategories =
      categories?.length > 0
        ? this.catalogPremiumEntries.filter((entry) => {
            const filtered = entry.categories?.filter((item) =>
              categories.includes(item),
            );
            return entry?.categories?.some((item) => filtered.includes(item));
          })
        : [];

    // _filteredSessionContent may contain multiple sessions
    let _filteredSessionContent =
      categories?.length > 0
        ? this.premiumSessionsStore.premiumContentWithScheduledSessions?.filter(
            (content) => {
              const splitOffering = content.offering_slug.split('-');

              return filteredPremiumCategories.some(
                (category) =>
                  category?.code === splitOffering[0] &&
                  category.version === splitOffering[1],
              );
            },
          ) || []
        : this.premiumSessionsStore.premiumContentWithScheduledSessions;

    // filter products
    const products = this.flattenFilters('products');
    const filteredPremiumProducts =
      products?.length > 0
        ? this.catalogPremiumEntries.filter((entry) => {
            const filtered = entry.products?.filter((item) =>
              products.includes(item),
            );
            return entry?.products?.some((item) => filtered.includes(item));
          })
        : [];
    _filteredSessionContent =
      products?.length > 0
        ? _filteredSessionContent?.filter((content) => {
            const splitOffering = content.offering_slug.split('-');

            return filteredPremiumProducts.some(
              (product) =>
                product?.code === splitOffering[0] &&
                product.version === splitOffering[1],
            );
          }) || []
        : _filteredSessionContent;

    if (newTaxonomiesEnabled) {
      const topics = this.flattenFilters('taxonomyTopics');
      if (topics?.length > 0) {
        const filteredPremiumTopics = this.catalogPremiumEntries.filter(
          (entry) => {
            const entryTopics = prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.topics,
              true,
            );
            const filtered = entryTopics?.filter((item) =>
              topics.includes(item),
            );
            return entryTopics?.some((item) => filtered.includes(item));
          },
        );

        _filteredSessionContent =
          _filteredSessionContent?.filter((content) => {
            const { code: offeringCode, version: offeringVersion } =
              extractFromSlug(content.offering_slug);

            return filteredPremiumTopics.some(
              (entry) =>
                entry?.code === offeringCode &&
                entry.version === offeringVersion,
            );
          }) || [];
      }

      const audiences = this.flattenFilters('taxonomyAudiences');
      if (audiences?.length > 0) {
        const filteredPremiumAudiences = this.catalogPremiumEntries.filter(
          (entry) => {
            const entryAudiences = prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.audiences,
              true,
            );
            const filtered = entryAudiences?.filter((item) =>
              audiences.includes(item),
            );
            return entryAudiences?.some((item) => filtered.includes(item));
          },
        );

        _filteredSessionContent =
          _filteredSessionContent?.filter((content) => {
            const { code: offeringCode, version: offeringVersion } =
              extractFromSlug(content.offering_slug);

            return filteredPremiumAudiences.some(
              (entry) =>
                entry?.code === offeringCode &&
                entry.version === offeringVersion,
            );
          }) || [];
      }

      const taxonomyProducts = this.flattenFilters('taxonomyProducts');
      if (taxonomyProducts?.length > 0) {
        const filteredPremiumTaxonomyProducts =
          this.catalogPremiumEntries.filter((entry) => {
            const entryProducts = prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.products,
              true,
            );
            const filtered = entryProducts?.filter((item) =>
              taxonomyProducts.includes(item),
            );
            return entryProducts?.some((item) => filtered.includes(item));
          });

        _filteredSessionContent =
          _filteredSessionContent?.filter((content) => {
            const { code: offeringCode, version: offeringVersion } =
              extractFromSlug(content.offering_slug);

            return filteredPremiumTaxonomyProducts.some(
              (entry) =>
                entry?.code === offeringCode &&
                entry.version === offeringVersion,
            );
          }) || [];
      }

      const level = this.flattenFilters('level');
      if (level?.length > 0) {
        const filteredPremiumLevel = this.catalogPremiumEntries.filter(
          (entry) => {
            const entryLevel = prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.level,
              false,
            );

            return level.includes(`${entryLevel}`);
          },
        );

        _filteredSessionContent =
          _filteredSessionContent?.filter((content) => {
            const { code: offeringCode, version: offeringVersion } =
              extractFromSlug(content.offering_slug);

            return filteredPremiumLevel.some(
              (entry) =>
                entry?.code === offeringCode &&
                entry.version === offeringVersion,
            );
          }) || [];
      }
    }

    // Only select session content i.e. premium clases that has some filtered scheduled sessions.
    // flatScheduledSessionDocIds contains only the ids of the sessions that fulfill the filters calculated above:
    const flatScheduledSessionDocIds = flatMap(
      this._filteredScheduledSessionEntries,
      'doc_id',
    );

    const hasFilter =
      this.filters.daterange[0]?.value?.from !== undefined ||
      this.filters.timerange[0]?.value?.from !== undefined ||
      sessionsFilteredByInstructors.length > 0 ||
      filteredLanguages.length > 0;

    this._filteredSessionContent = hasFilter
      ? _filteredSessionContent.filter((content) => {
          let hasAnySessionFulfillingFilters = false;

          if (content.scheduledSessions) {
            content.scheduledSessions = content.scheduledSessions.map(
              (session) => {
                const sessionScheduledState = [
                  PREMIUM_VT_SESSION_STATES.scheduled,
                ];
                const fulfillsActiveFilters =
                  sessionScheduledState.includes(session.state) &&
                  flatScheduledSessionDocIds.includes(session.doc_id);

                hasAnySessionFulfillingFilters =
                  hasAnySessionFulfillingFilters || fulfillsActiveFilters;

                // failsActiveFilters is true if a session does not pass the current filters
                return {
                  ...session,
                  failsActiveFilters: !fulfillsActiveFilters,
                };
              },
            );
          }

          return hasAnySessionFulfillingFilters;
        })
      : _filteredSessionContent;
  };

  // only catalogs that has premium classes
  /**
   * Get the catalog entries that have premium classes
   * @returns {Array} - Catalog entries that have premium classes
   */
  @computed get catalogPremiumEntries() {
    return this.catalogStore.entries?.filter((course) => {
      const courseSlug = toSlug(course.code, course.version);
      return this.premiumSessionsStore.premiumSessionsContent?.some(
        (p) => p.offering_slug === courseSlug,
      );
    });
  }

  /**
   * Populate filters for the UI with data from the catalog
   */
  @action getFilterInfo = async (
    i18n,
    t,
    vocabularyStore,
    filterQueryFunc = (x) => x,
  ) => {
    const { language } = i18n;
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );
    const { getVocabularyByNamespace } = vocabularyStore;
    this.filterQueryFunc = filterQueryFunc;
    const searchResults = filterQueryFunc
      ? this.premiumSessionsStore.premiumSessions.filter((s) => {
          return filterQueryFunc(s);
        })
      : this.premiumSessionsStore.premiumSessions;

    // categories
    const categoryLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.OfferingCategories,
      language,
    );

    this.filters.categories = CatalogStore.renderFilters({
      values: flatMap(this.catalogPremiumEntries, 'categories'),
      vocabulary: categoryLabels,
    });

    // products
    const productLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.OfferingProducts,
      language,
    );

    this.filters.products = CatalogStore.renderFilters({
      values: flatMap(this.catalogPremiumEntries, 'products'),
      vocabulary: productLabels,
    });

    if (newTaxonomiesEnabled) {
      const topicLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyTopics,
        language,
      );

      this.filters.taxonomyTopics = CatalogStore.renderFilters({
        values: this.catalogPremiumEntries
          .map((entry) =>
            prioritizeEntryField(entry, TAXONOMIES_PRIORITIES.new.topics, true),
          )
          .flat(),
        vocabulary: topicLabels,
      });

      const audienceLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyAudiences,
        language,
      );

      this.filters.taxonomyAudiences = CatalogStore.renderFilters({
        values: this.catalogPremiumEntries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.audiences,
              true,
            ),
          )
          .flat(),
        vocabulary: audienceLabels,
      });

      const taxonomyProductLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingTaxonomyProducts,
        language,
      );

      this.filters.taxonomyProducts = CatalogStore.renderFilters({
        values: this.catalogPremiumEntries
          .map((entry) =>
            prioritizeEntryField(
              entry,
              TAXONOMIES_PRIORITIES.new.products,
              true,
            ),
          )
          .flat(),
        vocabulary: taxonomyProductLabels,
      });

      const levelLabels = await getVocabularyByNamespace(
        VocabularyNamespaces.OfferingLevel,
        language,
      );

      this.filters.level = CatalogStore.renderFilters({
        values: this.catalogPremiumEntries
          .map((entry) =>
            prioritizeEntryField(entry, TAXONOMIES_PRIORITIES.new.level, false),
          )
          .flat(),
        vocabulary: levelLabels,
        compareFn: compareByLevelAsc,
      });
    }

    const flatInstructors = flatMap(searchResults, 'instructors');
    const uniqueInstructors = flatInstructors?.map((i) => {
      return i.username;
    });

    const instructors = flatInstructors?.map((i) => {
      return { token: i.username, display_name: i.name };
    });
    this.filters.instructors = CatalogStore.renderFilters({
      values: uniqueInstructors,
      vocabulary: instructors,
      preSelected: this.flattenFilters('instructors'),
    });

    // Languages
    const flatLanguages = flatMap(searchResults, 'instruction_language');

    const languageLabels = await getVocabularyByNamespace(
      VocabularyNamespaces.Languages,
      language,
    );
    this.filters.languages = CatalogStore.renderFilters({
      values: flatLanguages,
      vocabulary: languageLabels,
      preSelected: this.flattenFilters('languages'),
    });

    const selectedDateRange =
      this.filters.daterange?.length > 0 ? this.filters.daterange[0] : [];

    this.filters.daterange = [
      {
        key: 'daterange1',
        value: {
          from: selectedDateRange?.value?.from || undefined,
          to: selectedDateRange?.value?.to || undefined,
        },
        count: 0,
        checked: selectedDateRange?.checked || false,
      },
    ];

    const selectedTimeRange =
      this.filters.timerange?.length > 0 ? this.filters.timerange[0] : [];

    this.filters.timerange = [
      {
        key: 'timerange1',
        value: {
          from: selectedTimeRange?.value?.from || undefined,
          to: selectedTimeRange?.value?.to || undefined,
        },
        count: 0,
        checked: selectedTimeRange?.checked || false,
      },
    ];

    return this.filters;
  };

  @computed get dateRange() {
    return this.filters.daterange[0]?.value;
  }

  @computed get timeRange() {
    return this.filters.timerange[0]?.value;
  }

  @action setDateRange = (range) => {
    this.filters.daterange[0].value = merge(
      toJS(this.filters.daterange[0].value),
      range || {},
    );
  };

  @action setTimeRange = (range) => {
    this.filters.timerange[0].value = merge(
      toJS(this.filters.timerange[0].value),
      range || {},
    );
  };

  @action resetCalendar = () => {
    this.filters.daterange[0].value.from = undefined;
    this.filters.daterange[0].value.to = undefined;
  };

  @action resetCalendarTime = () => {
    this.filters.timerange[0].value.from = undefined;
    this.filters.timerange[0].value.to = undefined;
  };

  clearFilters = (exceptions = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
          if (['daterange', 'timerange'].includes(key)) {
            item.value = { from: undefined, to: undefined };
          }
        }
      });
    });

    this.filterEntries();
  };

  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    this.filterEntries();
  };

  toggleFilterGroups = (id, expanded) => {
    return expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);
  };

  @computed get hideFullSessions() {
    return this.filters.fullsessions[0].checked;
  }
}

export default PremiumSessionFilterStore;
