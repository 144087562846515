import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  Title,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
  Alert,
} from '@patternfly/react-core';
import { Helmet } from 'react-helmet';

import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';

import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { CenteredLoadingSpinner, useFeature } from '@ole-ui/ole-ui-components';

import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import ExamStore, { ExamStoreContext } from 'stores/Exam';
import './styles.scss';
import { extractFromSlug } from 'helpers/utils';

const ExamComponent = React.lazy(() =>
  import(/* webpackChunkName: 'exam-component' */ 'components/Exam/Exam'),
);

const ExamView = () => {
  const { alertStore, catalogStore, examsStore, routerStore, uiStore } =
    useContext(storesContext);

  const { i18n, t } = useTranslation();
  const examStore = useLocalStore(() => new ExamStore(catalogStore));
  const { route } = routerStore;
  const { collateral } = examStore;

  const isExamReadinessEnabled = useFeature({
    section: 'exam',
    flagName: 'exam_readiness',
    defaultFlagValue: false,
  });

  useEffect(() => {
    if (examStore.code) {
      const tabTitle = `${examStore.code.toUpperCase()}`;
      uiStore.title = tabTitle;
    }
  }, [examStore.code]);

  useEffect(() => {
    const { code, version } = extractFromSlug(route.params.slug);
    examStore.code = code;
    examStore.version = version;
    examStore.getUserExam();

    examStore.startPolling();
    return () => {
      examStore.stopPolling();
    };
  }, [route.params]);

  useEffect(() => {
    const { code } = extractFromSlug(route.params.slug);
    examStore.code = code;

    examStore.getExamCollateral(code, i18n.language);
  }, [route.params, i18n.language]);

  useEffect(() => {
    if (isExamReadinessEnabled) {
      examStore.getExamFaqPlaylist();
    }
  }, [isExamReadinessEnabled]);

  const alerts = alertStore.currentAlerts.filter((alert) => {
    return alert.environment.includes(route.params.slug?.replace('-1.0', ''));
  });

  const title = collateral?.title || `${examStore.offering?.sku}`;
  const pageTitle = examStore.offering?.sku
    ? `${examStore.offering?.sku} - ${title}`
    : title;

  return (
    <div className="container-grid-responsive">
      <React.Suspense
        fallback={
          <div className="exam-view__loading">
            <CenteredLoadingSpinner />
          </div>
        }
      >
        {examStore.offering && (
          <React.Fragment>
            <Helmet title={pageTitle} />
            <div>
              <h2>{title}</h2>
            </div>
            <div className="exam-alerts">
              {alerts.map((alert) => (
                <Alert
                  isInline
                  variant={alert.priority}
                  title={alert.title}
                  className="exam-view__alert"
                >
                  <p>{alert.description}</p>
                </Alert>
              ))}
            </div>
            <ExamStoreContext.Provider value={examStore}>
              <ExamComponent />
            </ExamStoreContext.Provider>
          </React.Fragment>
        )}
      </React.Suspense>
      {examStore.offering === null && examsStore.loading && (
        <div className="exam-view__loading">
          <CenteredLoadingSpinner />
        </div>
      )}
      {examStore.offering === undefined && (
        <EmptyState>
          <EmptyStateIcon icon={SearchIcon} />
          <Title size="lg" headingLevel="h4">
            {t('Exam not found')}
          </Title>
          <EmptyStateBody>
            {t(
              "Sorry, we can't find this exam right now. Please check again later.",
            )}
          </EmptyStateBody>
        </EmptyState>
      )}
    </div>
  );
};

export default withErrorBoundary(
  observer(ExamView),
  GenericViewError,
  handleError,
);
