import { get, post, patch } from 'axios';
import {
  ENROLLMENT_API,
  ENROLLMENT_INSTANCE,
  USER_ENROLLMENT_INFO_API,
} from '../config/constants';

/* get course enrollment info */
const getCourseEnrollment = (course, username) =>
  new Promise((resolve, reject) => {
    get(
      ENROLLMENT_API.replace('<course-slug>', course).replace(
        '<username>',
        username,
      ),
    )
      .then((result) => resolve(result.data.data.attributes))
      .catch((e) => reject(e));
  });

const getEnrollmentInstance = (uuid) =>
  new Promise((resolve, reject) => {
    get(ENROLLMENT_INSTANCE.replace('<uuid>', uuid))
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => reject(e));
  });

const saveEnrollmentUserData = (newUserData, uuid) =>
  new Promise((resolve, reject) => {
    patch(ENROLLMENT_INSTANCE.replace('<uuid>', uuid), {
      user_data: newUserData,
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((e) => reject(e));
  });

const getUserEnrollmentInfo = (username, slug) =>
  new Promise((resolve, reject) => {
    get(`${USER_ENROLLMENT_INFO_API}${username}@@${slug}/@@get`)
      .then((result) => resolve(result.data))
      .catch((e) => {
        if (e.response?.status === 404) {
          resolve(null);
        } else {
          reject(e);
        }
      });
  });

const saveUserEnrollmentInfo = async (
  newUserEnrollmentInfo,
  username,
  slug,
) => {
  try {
    const result = await post(
      `${USER_ENROLLMENT_INFO_API}${username}@@${slug}/@@update`,
      newUserEnrollmentInfo,
    );
    return result.data;
  } catch (error) {
    if (error.response?.status === 404) {
      try {
        const createResult = await post(`${USER_ENROLLMENT_INFO_API}@@create`, {
          username,
          offering_slug: slug,
          ...newUserEnrollmentInfo,
        });
        return createResult.data;
      } catch (createError) {
        throw createError;
      }
    }
    throw error;
  }
};

export {
  getCourseEnrollment,
  getEnrollmentInstance,
  saveEnrollmentUserData,
  getUserEnrollmentInfo,
  saveUserEnrollmentInfo,
};
export default getCourseEnrollment;
