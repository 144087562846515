import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Tooltip, Icon } from '@patternfly/react-core';
import WarningIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import { useTranslation } from 'react-i18next';
import { storesContext } from 'stores';
import { getLanguageInfo } from 'services/LanguageService';

function LanguagesMenu({ setMenuOpen }) {
  const { i18n, t } = useTranslation();
  const { uiStore } = useContext(storesContext);
  const { languages, currentLanguage, currentCourseLanguages } = uiStore;

  const renderCourseSupportIcon = (token, languageName) => {
    let courseSupportIcon = null;

    if (currentCourseLanguages) {
      if (!currentCourseLanguages.includes(token)) {
        courseSupportIcon = (
          <Tooltip
            content={
              <div>
                {t(
                  'Translation of this material is not available in {{languageName}} currently.',
                  {
                    languageName,
                  },
                )}
              </div>
            }
          >
            <Icon
              status="warning"
              size="md"
              className="language-menu__warning-icon"
            >
              <WarningIcon color="var(--pf-v5-global--warning-color--100)" />
            </Icon>
          </Tooltip>
        );
      }
    }
    return courseSupportIcon;
  };

  return (
    <Flex justifyContent={{ default: 'justifyContentCenter' }}>
      <Flex
        direction={{ default: 'column' }}
        justifyContent={{ default: 'justifyContentCenter' }}
        alignItems={{ default: 'alignItemsCenter' }}
      >
        <div className="language-menu">
          {languages.map((lang) => {
            const classNames = `button-link ${
              currentLanguage === lang.token ? 'button-link--selected' : ''
            }`;
            const localName = getLanguageInfo(lang.token)?.localName || '';

            return lang.token ? (
              <Button
                variant="link"
                className={classNames}
                key={lang.token}
                event={lang.token}
                style={{ display: 'block' }}
                aria-label={t('Change language to {{lang}} ({{localName}})', {
                  localName,
                  lang: lang.display_name,
                })}
                onClick={() => {
                  i18n.changeLanguage(lang.token);
                  setMenuOpen();
                }}
                data-analytics-id={`${lang.token}-language-header-lp`}
              >
                {renderCourseSupportIcon(lang.token, lang.display_name)}
                {t(`{{localName}}`, {
                  localName: localName || lang.token,
                })}
              </Button>
            ) : null;
          })}
        </div>
        {currentCourseLanguages && (
          <div>
            <p>
              {t(
                '*Note: Course content may not be available in your selected language.',
              )}
            </p>
          </div>
        )}
      </Flex>
    </Flex>
  );
}

LanguagesMenu.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
};

export default LanguagesMenu;
