/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { Alert, Panel, ProgressBar } from 'react-bootstrap';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';

import './styles.scss';
import OrderingButtons from './OrderingButtons';

const Card = ({
  children, // Elements between the Card tag will appear in the expanded view
  customClassName, // Custom CSS class name for the card to personalize further
  iconClassName, // Custom CSS class name for the icon to change sizes and positions
  svgIconElement, // SVG element from react-inlinesvg
  cardColor, // HTML code for the colored bottom
  iconWidth,
  iconHeight,
  leftPanelElement, // HTML element to be displayed in the left side, used in Dashboard cards
  firstLineElement, // HTML element to be displayed in the first line
  secondLineElement, // HTML element to be displayed in the second line
  beginExpanded, // Boolean to set if you want the card to be rendered open
  title, // String to be displayed as title
  subtitle, // String to be displayed next to the title using the cardColor
  label, // label similar to subtitle, but with dark rectangle background
  progress, // Number from 0 to 100, if exists
  indicatorElementsArray, // Array of elements to be displayed in the bottom left
  actionButtonsArray, // Array of buttons to be displayed in the bottom right
  coloredBottom, // Boolean to use the icon background color in the footer
  actionToggle, // Function to execute when the card is opened
  externallyExpanded, // Boolean to expand the card from a parent component
  renderChildrenByDefault, // Boolean used to render children when the card is collapsed. If sets false, children will be rendered only when card is expanded
  allowOrderingOffering, // Boolean used to decide if it is allowed to display ordering buttons or not
  onOfferingOrderChange, // Function passed into OrderingButtons to move one element up or down
  'data-analytics-id': dataAnalyticsId,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(beginExpanded || false);

  useEffect(() => {
    // Changes the internal state `isExpanded` to true using an external input via props
    if (externallyExpanded) {
      setIsExpanded(externallyExpanded);
    }

    if (isExpanded && !externallyExpanded) setIsExpanded(false);
  }, [externallyExpanded]);

  const onToggle = () => {
    actionToggle();
    if (children) setIsExpanded(!isExpanded);
  };

  return (
    <div className={`new-card ${customClassName || ''}`}>
      <Panel
        id="panel-card"
        expanded={isExpanded}
        onToggle={onToggle}
        data-analytics-id={dataAnalyticsId}
      >
        {allowOrderingOffering && (
          <OrderingButtons onOrderChange={onOfferingOrderChange} />
        )}

        {leftPanelElement && (
          <div className="left-side">{leftPanelElement}</div>
        )}
        <div
          onClick={(event) => {
            event.stopPropagation();
            onToggle();
          }}
          className="central-panel cursor-pointer"
        >
          {leftPanelElement && (
            <div className="left-side-mobile">{leftPanelElement}</div>
          )}
          <div className="head">
            {svgIconElement && (
              <div
                style={{
                  width: `${iconWidth}px`,
                  minWidth: `${iconWidth}px`,
                  height: `${iconHeight}px`,
                  minHeight: `${iconHeight}px`,
                }}
                className={`icon ${iconClassName || ''}`}
              >
                {svgIconElement}
              </div>
            )}
            <h4 className="card-title">
              {title}
              {subtitle && subtitle !== '' && (
                <React.Fragment>
                  <br />
                  <span
                    style={{ color: `${cardColor}` }}
                    className="card-subtitle"
                  >
                    {subtitle}
                  </span>
                </React.Fragment>
              )}
              {label && label !== '' && (
                <div>
                  <div className="card-label">{label}</div>
                </div>
              )}
            </h4>
          </div>
          <div className="body">
            {firstLineElement ? (
              <div className="first line">{firstLineElement}</div>
            ) : null}
            {secondLineElement ? (
              <div className="second line">{secondLineElement}</div>
            ) : null}
          </div>
          <Panel.Collapse>
            <Panel.Body>
              {!renderChildrenByDefault
                ? (isExpanded && children) || null
                : children}
            </Panel.Body>
          </Panel.Collapse>
          <div className="progress-bar-container">
            {progress > 0 && (
              <div className="progress-container">
                <ProgressBar now={progress} label={`${progress}%`} />
              </div>
            )}
          </div>
          <Panel.Footer
            style={{ backgroundColor: coloredBottom ? cardColor : '#f8f8f8' }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="footer-elements-container">
              <div
                className={`indicators-container${
                  indicatorElementsArray.length === 0 ? ' empty' : ''
                }`}
              >
                {indicatorElementsArray}
              </div>
              <div
                className="actions-container"
                style={coloredBottom ? { backgroundColor: cardColor } : {}}
              >
                {actionButtonsArray}
              </div>
            </div>
          </Panel.Footer>
        </div>
        {children && (
          <div
            role="button"
            tabIndex={0}
            aria-label={t('Expand course card to view course description')}
            className="right-side cursor-pointer"
            onClick={(event) => {
              event.stopPropagation();
              onToggle();
            }}
          >
            <span className="arrow-icon">
              <SVG src={isExpanded ? CollapseIcon : ExpandIcon}>
                {isExpanded ? t('Collapse') : t('Expand')}
              </SVG>
            </span>
          </div>
        )}
      </Panel>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.any,
  actionButtonsArray: PropTypes.array.isRequired,
  beginExpanded: PropTypes.bool,
  customClassName: PropTypes.string,
  leftPanelElement: PropTypes.any,
  firstLineElement: PropTypes.any,
  cardColor: PropTypes.string,
  iconClassName: PropTypes.string,
  indicatorElementsArray: PropTypes.array,
  progress: PropTypes.any,
  secondLineElement: PropTypes.any,
  svgIconElement: PropTypes.any.isRequired,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  coloredBottom: PropTypes.bool,
  actionToggle: PropTypes.func,
  externallyExpanded: PropTypes.bool,
  renderChildrenByDefault: PropTypes.bool,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
  'data-analytics-id': PropTypes.string,
};

Card.defaultProps = {
  children: undefined,
  cardColor: '',
  indicatorElementsArray: [],
  beginExpanded: false,
  customClassName: '',
  leftPanelElement: undefined,
  firstLineElement: '',
  secondLineElement: '',
  iconClassName: '',
  progress: undefined,
  coloredBottom: false,
  iconWidth: 45,
  iconHeight: 45,
  actionToggle: () => {},
  subtitle: '',
  label: '',
  externallyExpanded: false,
  renderChildrenByDefault: true,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
  'data-analytics-id': undefined,
};

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

export default withErrorBoundary(observer(Card), ErrorMessage);
