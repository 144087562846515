/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import { action, computed, observable } from 'mobx';
import Fuse from 'fuse.js';
import deepmerge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';
import lodashValues from 'lodash/values';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';

import {
  CatalogEntry,
  CourseType,
  Modality,
  OfferingCodePrefix,
  OfferingTier,
  OfferingVisibility,
  VocabularyItem,
} from 'types';
import { EntryProgressPair } from 'types/progress';
import { extractFromSlug } from 'helpers/utils';
import { getCatalog, getCatalogEntries } from 'services/CatalogService';

import { CatalogFilters, CatalogFilterState } from './types';
import { getFilterConfigurations } from './config';
import { getFilteredEntries } from './utils';

export const compareByLevelAsc = (
  a: CatalogFilterState,
  b: CatalogFilterState,
) => parseInt(a.key, 10) - parseInt(b.key, 10);

class CatalogStore {
  @observable subscriptionCatalog: CatalogEntry[] = [];

  @observable loadedSubscriptionCatalog: boolean = false;

  @observable loadingSubscriptionCatalog: boolean = false;

  @observable allCatalogEntries: CatalogEntry[] = [];

  @observable loadedCatalog: boolean = false;

  @observable loadingCatalog: boolean = false;

  @observable filterInfoLoaded = false;

  @observable filters: CatalogFilters = CatalogStore.initializeFilters();

  @observable managementOverviewFilters: CatalogFilters =
    CatalogStore.initializeFilters();

  @observable expandedFilterGroups = new Set();

  @observable _filteredEntries: CatalogEntry[] = [];

  @observable searchResults = null;

  @observable.ref courseCollateral = [];

  @observable _currentPage: number = 1;

  @observable entriesPerPage: number = 10;

  @observable selectedByExternalSubscriber = '';

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    if (!this.rootStore?.userStore.currentSubscriptionCode) {
      this.getAllCatalogEntries(false);
    }
  }

  @action searchEntries = (string: string) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['slug', 'title', 'modality', 'course_type'],
    };

    if (!string.length) {
      this.searchResults = this.filteredEntries;
      return;
    }

    const fuse = new Fuse(this.filteredEntries, options);
    this.searchResults = fuse.search(string);
  };

  @computed get query() {
    return this.rootStore.searchStore.query;
  }

  @computed get totalPages(): number {
    return Math.ceil(this.filteredEntries.length / this.entriesPerPage) || 1;
  }

  @computed get currentPage(): number {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get filterKeys(): string[] {
    return Object.keys(this.filters);
  }

  /**
   * Returns an unique array of catalog entries, preferring the latest version.
   * Only works on subscription based catalog.
   */
  @computed get uniqueCatalogEntries() {
    const translations = {};

    // Construct a dic of unique catalog entries, preferring the latest version
    const uniqueDict = this.subscriptionCatalog
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (
          !dict[entry.code] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.code].version)
        ) {
          dict[entry.code] = entry;
        }
        return dict;
      }, {});

    Object.entries(uniqueDict).forEach(([code, entry]: any) => {
      const translation = translations[entry.code] || [];

      uniqueDict[code] = deepmerge(entry, {
        translations: [
          {
            language: 'en-US',
            title: entry.title,
          },
          ...translation,
        ],
      });
    });

    return uniqueDict;
  }

  @computed get entries(): Array<CatalogEntry> {
    return Object.values(this.uniqueCatalogEntries);
  }

  /**
   * Same as uniqueCatalogEntries, but does it with all the available catalog entries.
   * Useful mostly for cases in which the user doesn't have an active subscription,
   * such as for managers, alacarte and open users, or text searches.
   */
  @computed get allUniqueCatalogEntries() {
    const translations = {};

    const uniqueDict = this.allCatalogEntries
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (
          !dict[entry.code] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.code].version)
        ) {
          dict[entry.code] = entry;
        }
        return dict;
      }, {});

    Object.entries(uniqueDict).forEach(([code, entry]: any) => {
      const translation = translations[entry.code] || [];

      uniqueDict[code] = deepmerge(entry, {
        translations: [
          {
            language: 'en-US',
            title: entry.title,
          },
          ...translation,
        ],
      });
    });

    return uniqueDict;
  }

  set entries(entries) {
    this.subscriptionCatalog = entries;
  }

  @computed get filteredEntries() {
    const checkedFiltersLength = flatMap(
      this.filterKeys.map((key) =>
        this.filters[key].filter((filter) => filter.checked),
      ),
    ).length;

    return checkedFiltersLength || this.query
      ? this._filteredEntries
      : this.entries;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.filteredEntries.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  /**
   * Filters the catalog entries based on the checked filters.
   * If a query is present, it will also filter the entries based on the query.
   */
  @action filterEntries = () => {
    const newTaxonomiesEnabled = this.rootStore.featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );

    const filterConfigurations = getFilterConfigurations(newTaxonomiesEnabled);

    this._filteredEntries = getFilteredEntries<CatalogEntry>(
      this.entries,
      filterConfigurations,
      this.filters,
    ) as CatalogEntry[];

    if (this.query) {
      this.searchEntries(this.query);
      this._filteredEntries = this.searchResults;
    }
  };

  /**
   * Fetches the catalog entries for a given subscription.
   * @param {string} subscriptionCode - the subscription code to fetch the catalog entries for
   * @param {boolean} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getCatalogEntriesBySubscrition = async (
    subscriptionCode: string,
    force: boolean,
  ) => {
    if (this.loadedSubscriptionCatalog && !force) {
      return this.subscriptionCatalog;
    }

    if (!subscriptionCode) {
      return this.subscriptionCatalog;
    }

    this.loadingSubscriptionCatalog = true;
    try {
      const subscriptionEntries: CatalogEntry[] = await getCatalog(
        subscriptionCode,
      );

      if (subscriptionEntries) {
        CatalogStore.sortExpertSeminars(subscriptionEntries);

        this.subscriptionCatalog = subscriptionEntries;
      }
    } finally {
      this.loadingSubscriptionCatalog = false;
      this.loadedSubscriptionCatalog = true;
    }
    return this.subscriptionCatalog;
  };

  /**
   * Fetches the catalog entries for the current subscription.
   * @param {boolean} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getCurrentSubscriptionCatalog = async (
    force: boolean,
  ): Promise<Array<CatalogEntry>> =>
    this.getCatalogEntriesBySubscrition(
      this.rootStore.userStore.currentSubscriptionCode,
      force,
    );

  /**
   * Fetches all catalog entries, independent from any subscription. Usually used for, but not limited to, alacarte users.
   * @param {*} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getAllCatalogEntries = async (
    force: boolean,
  ): Promise<Array<CatalogEntry>> => {
    if (this.allCatalogEntries.length && !force) {
      return this.allCatalogEntries;
    }

    this.loadingCatalog = true;

    const allCatalogEntries: Array<CatalogEntry> = await getCatalogEntries();
    if (allCatalogEntries) {
      this.allCatalogEntries = allCatalogEntries;
    }

    this.loadingCatalog = false;
    this.loadedCatalog = true;

    return this.allCatalogEntries;
  };

  /**
   * Returns a dictionary of all catalog entries grouped by course code and version.
   * Works for alacarte users.
   */
  @computed get groupedAllCatalogEntries() {
    return CatalogStore.composeCatalogGroups(this.allCatalogEntries);
  }

  /**
   * load the filters for the catalog entries. This is used to populate the filters in the UI.
   * This loads the vocabulary data for each filter type and then renders the filters based on
   * what is available in the the catalog entries. This ensures that filters only show up if they
   * exist in at least one catalog entry.
   */
  @action getFilterInfo = async (
    entryList: Array<CatalogEntry> = this.entries || [],
    isManagementOverview: boolean = false,
  ) => {
    this.filterInfoLoaded = false;
    const language = this.rootStore.uiStore.currentLanguage;
    const { featuresStore, vocabularyStore } = this.rootStore;
    const { getVocabularyByNamespace } = vocabularyStore;

    const fieldName = isManagementOverview
      ? 'managementOverviewFilters'
      : 'filters';

    const newTaxonomiesEnabled: boolean = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );

    const filterConfigurations = getFilterConfigurations(newTaxonomiesEnabled);

    // get all the vocabularies at once (async, although they should be in memory already)
    const vocabularies = await Promise.all(
      filterConfigurations.map((config) =>
        config.vocabulary
          ? config.vocabulary
          : getVocabularyByNamespace(config.vocabularyNamespace, language),
      ),
    );

    filterConfigurations.forEach((config, index) => {
      const vocabulary = vocabularies[index];

      this[fieldName][config.key] = CatalogStore.renderFilters({
        values: config.extractFilterValues(entryList),
        vocabulary,
        compareFn: config.compareFn,
      });
    });

    this.filterInfoLoaded = true;
    return this[fieldName];
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @action clearCatalog = () => {
    this.subscriptionCatalog = [];
  };

  /**
   * Clears all filters except for the ones specified in the exceptions array. This applies to all filters at once.
   * @param exceptions - an array of filter values to clear from filters
   */
  clearFilters = (exceptions: string[] = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item: CatalogFilterState) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
        }
      });
    });

    this.filterEntries();
  };

  /**
   * Presets the filters based on an array of filter keys.
   * @param {Array} filters - an array of filter keys to preset to checked
   */
  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    this.filterEntries();
  };

  toggleFilterGroups = (id, expanded) =>
    expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);

  /**
   * Returns a dictionary of all catalog entries of a given subscription grouped by course code and version.
   * Works for subscription users only. Will be empty for alacarte users.
   */
  @computed get groupedCatalogEntries() {
    return CatalogStore.composeCatalogGroups(this.subscriptionCatalog);
  }

  subscriptionHasCourseType = (courseType) =>
    (this.subscriptionCatalog?.find(
      (entry) =>
        entry?.course_type === courseType &&
        entry?.visibility !== OfferingVisibility.Hidden,
    ) &&
      true) ||
    false;

  @computed get subscriptionHasLabPlus() {
    return this.subscriptionHasCourseType(CourseType.LabPlus);
  }

  @computed get subscriptionHasLesson() {
    return this.subscriptionHasCourseType(CourseType.Lesson);
  }

  @computed get groupedAlaCarteEnrollments() {
    const { classesStore } = this.rootStore;

    const uniqueDict = Object.values(classesStore.offerings)
      .filter(
        (entry: any) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict: any, entry: any) => {
        if (
          !dict[entry.slug] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.slug].version)
        ) {
          dict[entry.slug] = entry;
        }
        return dict;
      }, {});

    return Object.values(uniqueDict);
  }

  @computed get groupedAlaCarteEnrollmentsByCode() {
    return mapValues(groupBy(this.groupedAlaCarteEnrollments, 'code'), (o) =>
      keyBy(o, 'version'),
    );
  }

  @computed get groupedAlaCarteCatalogEntriesAsArray() {
    const allowedToView = pickBy(this.groupedAllCatalogEntries, (value, key) =>
      Boolean(this.groupedAlaCarteEnrollmentsByCode[key]),
    );
    return lodashValues(allowedToView);
  }

  @computed get loading(): boolean {
    return this.loadingCatalog || this.loadingSubscriptionCatalog;
  }

  @computed get loaded(): boolean {
    if (this.rootStore.userStore.hasActiveSubscription) {
      return this.loadedSubscriptionCatalog;
    }
    return this.loadedCatalog;
  }

  @action removeModalityFromCatalog(modalities: string[]) {
    if (!modalities) return;

    this.subscriptionCatalog = this.subscriptionCatalog?.filter(
      (entry) => !modalities.includes(entry.modality),
    );
  }

  @action getSortedVersions(code) {
    return this.subscriptionCatalog
      .filter((a) => a.code === code)
      .map((a) => a.version)
      .sort((a, b) => {
        const aa = a
          .split('.')
          .map((n) => +n + 100000)
          .join('.');
        const bb = b
          .split('.')
          .map((n) => +n + 100000)
          .join('.');
        if (aa < bb) return -1;
        if (aa > bb) return 1;
        return 0;
      });
  }

  @action getLatestVersion(code: string) {
    const versions = this.getSortedVersions(code);
    if (!versions.length) {
      return null;
    }

    return versions.slice(-1)[0];
  }

  getTranslatedCourseName(
    slugOrCode: string,
    locale = 'en',
    uniqueCatalogEntries = this.uniqueCatalogEntries,
  ) {
    const { code } = extractFromSlug(slugOrCode);
    const entry = uniqueCatalogEntries[code];
    const translatedName = entry?.translations?.find(
      (element) => element.language === locale,
    )?.title;

    return translatedName || entry?.title || code;
  }

  /**
   * Given a course code, returns any valid full slug (code + version) for the respective entry.
   * Useful for when we need the slug for linking to a course, but we don't know the version.
   * Does not guarantee the highest version.
   * @param {*} code course code without version
   * @param {*} uniqueCatalogEntries
   * @returns course slug (code + version)
   */
  getAnySlugFromCode(code, uniqueCatalogEntries = this.uniqueCatalogEntries) {
    if (code.includes('-')) {
      return code;
    }

    const entry = uniqueCatalogEntries[code];

    return entry.slug;
  }

  @computed get hasCatalogLoaded() {
    return !(
      isEmpty(this.groupedCatalogEntries) &&
      isEmpty(this.groupedAllCatalogEntries)
    );
  }

  @computed get RHLSTrialEntryProgressPair(): EntryProgressPair[] {
    const { progressDict } = this.rootStore.progressStore;

    // filter only visible RHLSTrial courses
    const filtered =
      this.entries?.filter?.(
        (entry) =>
          entry.tier === OfferingTier.RHLSTrial &&
          entry.visibility === OfferingVisibility.Visible,
      ) || [];

    // attach progress to each entry
    let pairList: EntryProgressPair[] = filtered.map((entry) => ({
      entry,
      progress: progressDict?.[entry.slug],
    }));

    // sort by progress by most recent at the top.
    pairList = pairList.sort((a, b) => {
      if (!a.progress && !b.progress) {
        return 0;
      }

      if (!a.progress) {
        return 1;
      }

      if (!b.progress) {
        return -1;
      }

      const dateA = new Date(a.progress['@timestamp']).getTime();
      const dateB = new Date(b.progress['@timestamp']).getTime();

      return dateB - dateA;
    });

    return pairList;
  }

  getCatalogEntryForCourse(courseCode: string, courseVersion: string) {
    const code = `${courseCode}`.toLowerCase();

    return (
      this.groupedCatalogEntries?.[code]?.[courseVersion] ||
      this.groupedAllCatalogEntries?.[code]?.[courseVersion]
    );
  }

  private static initializeFilters(): CatalogFilters {
    return {
      categories: [],
      products: [],
      earlyAccess: [],
      languages: [],
      deliveryFormats: [],
      roles: [],
      taxonomyProducts: [],
      taxonomyAudiences: [],
      taxonomyTopics: [],
      level: [],
    };
  }

  private static sortExpertSeminars(catalogEntries: CatalogEntry[]) {
    catalogEntries.sort((itemA, itemB) => {
      if (
        itemA.modality !== Modality.ExpertSeminar ||
        itemB.modality !== Modality.ExpertSeminar
      )
        return 0;

      const valueA = +itemA.code.slice(4);
      const valueB = +itemB.code.slice(4);

      const result = valueB - valueA;

      return result || 0;
    });
  }

  static isGreaterVersion(v1, v2) {
    return (
      v1
        .split('.')
        .map((n) => +n + 100000)
        .join('.')
        .localeCompare(
          v2
            .split('.')
            .map((n) => +n + 100000)
            .join('.'),
        ) > 0
    );
  }

  /**
   * Calculates the count for each filter option as well as sort them by a compare function.
   * Given an array of values, returns an array of objects with the following properties:
   * @param {Array} values - an array of filter values to be counted for each filter option
   * @param {Array} vocabulary - an array of objects with display_name and token properties
   */
  static renderFilters({
    values,
    vocabulary = [],
    compareFn = null,
    preSelected = [],
    missingVocabularyPolicy = 'ignore',
  }: {
    /**
     * An array of values to be counted for each filter option.
     */
    values: any[];
    /**
     * An array of vocabulary items to use for display names.
     */
    vocabulary?: VocabularyItem[];
    /**
     * A compare function to sort the filters by. If not provided, will sort by count in descending order.
     */
    compareFn?:
      | ((a: CatalogFilterState, b: CatalogFilterState) => number)
      | null;
    /**
     * An array of pre-selected filter keys to be checked as true.
     */
    preSelected?: any[];
    /**
     * If set to 'ignore', will not render filters that do not have a corresponding vocabulary entry.
     * If set to 'useValues', will render filters with the value as the display name.
     */
    missingVocabularyPolicy?: 'ignore' | 'useValues';
  }) {
    const count = countBy(values);

    const filters = Object.keys(count).reduce(
      (filtered: CatalogFilterState[], entryId) => {
        const foundVocabulary = vocabulary.find(
          (item) => entryId === item.token,
        );

        let value = '';

        if (!foundVocabulary) {
          if (missingVocabularyPolicy === 'ignore') {
            return filtered;
          }
          value = entryId;
        } else {
          value = foundVocabulary.display_name;
        }

        const checked = preSelected.includes(entryId) || false;

        filtered.push({
          key: entryId,
          value,
          count: count[entryId],
          checked,
        } as CatalogFilterState);

        const compareByCount = (a: CatalogFilterState, b: CatalogFilterState) =>
          b.count - a.count;

        return filtered.sort(compareFn || compareByCount);
      },
      [],
    );
    return filters;
  }

  static composeCatalogGroups(catalogEntries: CatalogEntry[]) {
    // Construct a dict of all offerings by course code and version, omitting video classrooms
    const versionedEntries = catalogEntries
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (!dict[entry.code]) {
          dict[entry.code] = {};
        }
        const translation = entry.translations || [];

        dict[entry.code][entry.version] = deepmerge(entry, {
          translations: [
            {
              language: entry.language,
              title: entry.title,
            },
            ...translation,
          ],
        });
        return dict;
      }, {});

    // deepmerge courses
    return versionedEntries;
  }

  static isLocked(offeringVisibility: OfferingVisibility) {
    return (
      offeringVisibility === OfferingVisibility.Upgrade ||
      offeringVisibility === OfferingVisibility.Hidden
    );
  }

  static isEarlyAccess(offeringCode: string) {
    return !!offeringCode?.endsWith(OfferingCodePrefix.EarlyAccess);
  }

  static isRegularCourse(offeringType: CourseType) {
    return offeringType === CourseType.Regular;
  }

  static isLabPlus(offeringType: CourseType) {
    return offeringType === CourseType.LabPlus;
  }

  static isLesson(offeringType: CourseType) {
    return offeringType === CourseType.Lesson;
  }
}

export default CatalogStore;
