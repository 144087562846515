/**
 * @file Course Page view
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { when } from 'mobx';

import { Alert, Col, PageHeader, Row, Tab, Tabs } from 'react-bootstrap';
import { Button, Flex } from '@patternfly/react-core';
import BullHornIcon from '@patternfly/react-icons/dist/esm/icons/bullhorn-icon';
import moment from 'moment';
import { BaseLink } from 'react-mobx-router5';
import { withErrorBoundary } from 'react-error-boundary';
import { CenteredLoadingSpinner, FeatureFlag } from '@ole-ui/ole-ui-components';
import GenericViewError from 'components/Error/GenericViewError';
import ViewLoader from 'components/Loading/View';
import TableOfContents from 'components/Course/TableOfContents';
import LessonOverview from 'components/Course/LessonOverview';
import ProgressMap from 'components/Course/ProgressMap';
import Navigation from 'components/Course/Navigation';
import ContentArea from 'components/Course/ContentArea';
import BookmarkButton from 'components/Course/BookmarkButton';
import Favorites from 'components/Course/Favorites';
import LabPolling from 'components/LabPolling';
import EBookDownloader from 'components/EBookDownloader';
import CertificateOfAttendanceButton from 'components/CertificateOfAttendance/Button';
import VersionsDropdown from 'components/Versions/Dropdown';
import LabEnvironment from 'components/LabEnvironment';
import SubscriptionComparisonTable from 'components/Subscriptions/ComparisonTable';
import TabTitle from 'components/TabTitle';
import GenericAlert from 'components/Banners/GenericAlert';
import LabMaintenanceBanner from 'components/Banners/Maintenance';
import ContentVersionHint from 'components/ContentVersionHint';
import VideoWidget from 'components/Video/Widget';
import LabStore from 'stores/Lab';
import { CourseFormat } from 'types';
import LabControlsStore, { labControlsContext } from 'stores/LabControls';
import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';
import { KALTURA_CONFIGS } from 'config/constants';
import CatalogCard from 'components/Catalog/Card';

import PageSkeletonLoading from '../skeletons/PageSkeletonLoading';
import BundledCourse from '../BundledCourse';
import useCourseCompletionNotification from '../useCourseCompletionNotification';

import LegacyCourseStore, { courseContext } from './legacyStore';

import '../styles.scss';

const LabStartDialog = React.lazy(() =>
  import(
    /* webpackChunkName: 'lab-start-dialog' */ 'components/LabControls/LabStartDialog'
  ),
);

const SurveyDialog = React.lazy(() =>
  import(
    /* webpackChunkName: 'survey-dialog' */ 'components/Course/SurveyDialog/SurveyDialog'
  ),
);

const DecomissionBanner = React.lazy(() =>
  import(
    /* webpackChunkName: 'decomission-banner' */ 'components/Course/DecomissionBanner/DecomissionBanner'
  ),
);

const SyndicationComponent = React.lazy(() =>
  import(
    /* webpackChunkName: 'syndication-component' */ '../../../components/SyndicationComponent'
  ),
);

const CourseView = () => {
  const { t } = useTranslation();

  const {
    routerStore,
    progressStore,
    uiStore,
    userStore,
    classesStore,
    alertStore,
    eventStore,
    catalogStore,
    configStore,
  } = useContext(storesContext);
  const courseStore = useLocalStore(
    () => new LegacyCourseStore(routerStore, uiStore, userStore, catalogStore),
  );

  const labControlsStore = useLocalStore(() => new LabControlsStore());

  const { route, router } = routerStore;
  const { course: courseSlug, page: pageId } = route.params;

  const {
    activeTab,
    completionTime,
    content,
    courseCode,
    courseInfo,
    courseStructure,
    courseVersion,
    euuid,
    page,
    buildEnv,
    sortedVersions,
    pageTitle,
    pageSlug,
    isLatestVersion,
    labMaintenanceBanners,
    modality,
    showSurvey50Dialog,
    showCertAttendanceButton,
    courseCatalogEntry,
    isEarlyAccess,
    isLabPlus,
    isLesson,
  } = courseStore;

  const labStore = useLocalStore(() => new LabStore());

  const { labBlueprints, labDefinition } = labStore;

  const { next_tag: nextTag, page_tag: pageTag, prev_tag: previous } = page;

  const {
    isFreeTierSubscriber,
    isLS101Subscriber,
    isExternalSubscriber,
    isXS001Subscriber,
    user,
    username,
    isBookshelfEnabledSubscription,
  } = userStore;

  const { currentAlerts } = alertStore;
  const { isChineseInstance } = uiStore;

  const canAccessBookshelf =
    !(isLabPlus || isEarlyAccess || isLesson) && isBookshelfEnabledSubscription;

  const isOpenSubscriberForCourse = userStore.isOpenSubscriberForCourse(
    courseStore.courseSlug,
  );

  const renderLabPlusCourses = (labPlusCode, offeringIndex) => {
    const offering = courseStore.catalogStore.uniqueCatalogEntries[labPlusCode];
    if (offering) {
      return (
        <div className="lab-plus-tab-content">
          <CatalogCard
            course={offering}
            key={`lab-plus-card-${labPlusCode}-${offeringIndex}`}
          />
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (courseCode && pageSlug) {
      const tabTitle = `${courseCode.toUpperCase()} - ${pageSlug}`;
      uiStore.title = tabTitle;
      document.title = tabTitle;
    }
  }, [courseCode, pageSlug]);

  let next = nextTag;

  if (nextTag) {
    // check that next page/chapter is available to the user.
    const nextItem = courseStructure.find((item) => item.page_tag === nextTag);
    next =
      nextTag &&
      nextItem &&
      nextItem.status !== 'inactive' &&
      nextItem.status !== 'hidden'
        ? nextTag
        : null;
  }

  const progressStructure =
    progressStore.progressDict[courseSlug]?.structure || [];

  const getCourseStructure = () => {
    if (
      !courseStore.isCourse ||
      courseCatalogEntry?.format === CourseFormat.Bundle
    ) {
      return;
    }

    courseStore.getCourseStructure();
  };

  const completePageProgress = async () => {
    try {
      await progressStore.setCourseProgress(
        courseStore.courseSlug,
        courseStore.pageUUID,
      );
    } catch (error) {
      console.error('Cannot complete page:', error);
    }
  };

  const completeBundleCourseProgress = async () => {
    try {
      await progressStore.setCourseProgress(courseStore.courseSlug);
    } catch (error) {
      console.error('Cannot complete course:', error);
    }
  };

  const getPageContent = async () => {
    if (
      !courseStore.isCourse ||
      courseCatalogEntry?.format === CourseFormat.Bundle ||
      !courseStore.pageSlug
    ) {
      return;
    }

    const coursePage = await courseStore.getCoursePage();

    if (!coursePage?.uuid) return;

    try {
      await courseStore.saveEnrollmentUserData();
      await courseStore.saveUserEnrollmentInfo();
    } catch (error) {
      console.error('Failed saving enrollment user data', error);
    }

    try {
      await completePageProgress();
    } catch (error) {
      console.error('Failed completing page progress', error);
    }

    if (isXS001Subscriber) {
      const courseProgress = progressStore.getProgressForCourse(courseSlug);
      if (
        courseProgress &&
        (progressStore.didJustEnrolledInCourse(courseProgress) ||
          progressStore.didJustFinishedCourse(courseProgress))
      ) {
        try {
          progressStore.setCourseProgressExternalSubscription(
            courseCode,
            courseProgress,
            userStore.subscription.subscription,
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  let joinRetries = 3;

  const loadCourseInfo = async () => {
    await courseStore.getCourseInfo();
  };
  const joinCourse = async () => {
    const {
      getCourseEnrollment,
      getEnrollmentInstance,
      getUserEnrollmentInfo,
    } = courseStore;

    if (!courseInfo) {
      if (joinRetries < 1) {
        return;
      }

      setTimeout(() => {
        loadCourseInfo();
        joinCourse();
        joinRetries -= 1;
      }, 1000);
      return;
    }

    try {
      if (courseCatalogEntry?.format !== CourseFormat.Bundle) {
        await getCourseStructure();
        await getCourseEnrollment(username);
        await getEnrollmentInstance();
        await getUserEnrollmentInfo();

        const lastPageAccessed =
          courseStore?.userEnrollmentInfo?.last_page_accessed ||
          courseStore?.courseStructure[0]?.page_tag ||
          'ch01';

        // Navigate to bookmarked page if the page id is not passed
        if (!pageId && lastPageAccessed) {
          courseStore.pageSlug = lastPageAccessed;
        }

        // TODO: Add error here if we're not able to enroll in a course
        const event = await courseStore.joinCourse();

        if (!event) {
          // TODO: Log error
        }

        if (!userStore.isOpenSubscriber) {
          await courseStore.getPageVideosForCourse();
        }
      }
    } catch (error) {
      console.error('Cannot retrieve course enrollment', error);
    } finally {
      courseStore.enrollmentFetched = true;
    }
  };

  const handlePageRender = () => {
    if (
      !courseStore.isCourse ||
      courseCatalogEntry?.format === CourseFormat.Bundle
    ) {
      return;
    }
    courseStore.activeTab = 2;

    router.replaceHistoryState('courses:page', {
      course: courseStore.courseSlug,
      page: courseStore.pageSlug,
    });

    getPageContent();
  };

  const onTabChange = (key) => {
    if (key === 'eBook-download-btn') {
      return;
    }

    courseStore.activeTab = key;
  };

  // Set the course slug each time the route changes
  useEffect(() => {
    if (!courseSlug) return;

    async function loadInfo() {
      await loadCourseInfo();
    }

    courseStore.courseSlug = courseSlug;
    labStore.courseSlug = courseSlug;

    // set course code quotas and configuration for alacarte user
    if (userStore.isAlaCarte) {
      userStore.setEnablementsQuotasConfigurations(courseSlug);
    }

    loadInfo();
  }, [courseSlug]);

  useEffect(() => {
    when(
      () => courseStore.language && courseStore.currentCourse,
      async () => {
        if (courseStore.language) {
          await getCourseStructure();
          await getPageContent();
        }
      },
    );
  }, [courseStore.language]);

  useEffect(() => {
    async function joinTheCourse() {
      await joinCourse();
    }

    if (
      courseCatalogEntry &&
      courseCatalogEntry.format !== CourseFormat.Bundle
    ) {
      labStore.getLabDefinition();
      courseStore.getCourseOffering();
    }

    if (courseCatalogEntry) {
      joinTheCourse();
    }
  }, [courseCatalogEntry, courseStore.courseInfoFetched]);

  useEffect(() => {
    if (!courseSlug) return;

    labStore.courseInfo = courseInfo;
  }, [courseInfo]);

  // Set the page slug each time the route changes
  useEffect(() => {
    if (!pageId) return;

    courseStore.pageSlug = pageId;
  }, [pageId]);

  useEffect(() => {
    if (isLesson) {
      courseStore.getCourseMetadata(courseStore.courseSlug);
    }
    if (
      !courseCatalogEntry ||
      !(courseStore.modality && courseStore.pageSlug)
    ) {
      return;
    }
    handlePageRender();
  }, [courseStore.modality, courseStore.pageSlug, courseCatalogEntry]);

  const showLabStartDialog =
    labControlsStore.courseSlug &&
    !(labControlsStore.blueprints?.length > 1) &&
    !(labControlsStore.created || labControlsStore.started) &&
    courseStore.showAutostart &&
    userStore.hasLabHours;

  let courseTotalProgress = null;
  let courseCompletionTime = '';

  const courseProgress = () => {
    const found = progressStore.progressByCourse.find((course) => {
      const { course_code: code1 } = course || {};
      return code1 === courseCode;
    });

    const {
      total_progress: totalProgress,
      completion_time: progressCompletionTime,
    } = found || {};

    courseTotalProgress = found ? Math.floor(totalProgress * 100) : null;
    courseCompletionTime = progressCompletionTime;
  };
  courseProgress();

  const is50reached = () => {
    return Number(courseTotalProgress) >= 50;
  };

  const canShowCertAttendanceButton = () => {
    return (
      progressStore.shouldShowLegacyCertificateOfAttendance(
        Number(courseTotalProgress),
        courseCompletionTime,
      ) && !isLesson
    );
  };

  useCourseCompletionNotification(
    !canShowCertAttendanceButton() && !isLesson,
    courseTotalProgress,
    courseSlug,
  );

  const showSurveyDialog =
    showSurvey50Dialog && courseStore.showSurvey && is50reached() && !isLesson;

  const showAttendanceButton =
    showCertAttendanceButton && canShowCertAttendanceButton();

  const dateToTimestamp = (date) => {
    const dateObj = date ? moment(date) : moment();
    return dateObj.toISOString();
  };

  const prepareFeedback = () => {
    const data = {
      course: courseCode,
      courseVersion,
      pageTitle: page?.title,
      uuid: page?.uuid,
      url: window.location.href,
    };
    eventStore.toggleFeedbackForm(data);
  };

  useEffect(() => {
    classesStore.getClassesForCurrentUser();

    return () => {
      uiStore.reportActiveCourseTranslations(null);
    };
  }, []);

  const courseAlerts = currentAlerts.filter(
    (alert) => alert.enabled && alert.environment.includes(courseSlug),
  );

  useEffect(() => {
    if (!courseSlug || !courseCatalogEntry) return;

    if (courseCatalogEntry?.format !== CourseFormat.Bundle) {
      courseStore.getCourseInfoMeta();
      courseStore.activeTab = 2;
    }
  }, [courseSlug, courseCatalogEntry]);

  // TODO: Refactory this whole component and separate course types into different components
  // also, things like toolbar should also become separate components

  if (courseCatalogEntry?.format === CourseFormat.Bundle) {
    const showToolbar =
      !isFreeTierSubscriber &&
      !isOpenSubscriberForCourse &&
      courseCode &&
      courseVersion &&
      !isLS101Subscriber &&
      !isExternalSubscriber;

    return (
      <BundledCourse
        onComplete={completeBundleCourseProgress}
        bundleReference={courseCatalogEntry.bundle_reference}
        showToolbar={showToolbar}
        prepareFeedback={prepareFeedback}
        title={courseStore.title}
      />
    );
  }

  if (!courseCatalogEntry) {
    return (
      <div className="course-loading">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  return (
    <div
      className={`container-grid-responsive course ${
        courseStore.isVideoClassroom && 'video-classroom'
      }`}
    >
      {(courseStore.enrollmentNotFound || courseStore.contentNotFound) && (
        <Alert className="not-found-msg" bsStyle="danger">
          {t(
            'We are sorry, this content is not available. Please click Support at the top of the page to submit a ticket to our Support team.',
          )}
        </Alert>
      )}
      {courseStore.title && <PageHeader>{courseStore.title}</PageHeader>}
      {courseStore.showDecomissionBanner ? (
        <courseContext.Provider value={courseStore}>
          <DecomissionBanner
            course={`${courseStore.courseCode}-${courseStore.courseVersion}`}
          />
        </courseContext.Provider>
      ) : null}
      {courseAlerts.map((alert) => (
        <GenericAlert {...alert} />
      ))}
      {showSurveyDialog ? (
        <courseContext.Provider value={courseStore}>
          <SurveyDialog
            onEnrollmentSave={courseStore.saveEnrollmentUserData}
            enrollmentUserData={courseStore.userData}
            courseCode={courseCode}
            courseSlug={courseSlug}
            modality={modality}
            courseVersion={courseVersion}
          />
        </courseContext.Provider>
      ) : null}
      {showLabStartDialog ? (
        <courseContext.Provider value={courseStore}>
          <labControlsContext.Provider value={labControlsStore}>
            <LabStartDialog
              onEnrollmentSave={courseStore.saveEnrollmentUserData}
              enrollmentUserData={courseStore.userData}
            />
          </labControlsContext.Provider>
        </courseContext.Provider>
      ) : null}
      {labMaintenanceBanners.map((notification) => (
        <LabMaintenanceBanner
          timeframe={notification.timeframe}
          hours={notification.hours}
        />
      ))}
      {/* TODO for monday: add the remaining content: */}
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col sm={12}>
            <section className="course-container">
              {courseStore.isVideoClassroom &&
                isFreeTierSubscriber &&
                euuid && (
                  <React.Fragment>
                    <div className="free-tier-redirect-wrapper center">
                      <p>
                        {t("Looks like you're on the wrong page.")}
                        <br />
                        {t(
                          'Free trial of video classroom is available by the link below.',
                        )}
                      </p>
                      <BaseLink
                        routerStore={routerStore}
                        routeName="courses:free-tier-vc"
                        routeParams={{
                          course: courseCode,
                        }}
                        href
                      >
                        <Button bsStyle="primary">
                          {t('Go to Video Classroom').toUpperCase()}
                        </Button>
                      </BaseLink>
                    </div>
                  </React.Fragment>
                )}
              {/* Toolbar begins here */}
              {(!isFreeTierSubscriber || !courseStore.isVideoClassroom) && (
                <div className="course-toolbar">
                  <div className="certification-btn-container">
                    {completionTime && (
                      <CertificateOfAttendanceButton
                        courseSlug={courseSlug}
                        timestamp={completionTime}
                      />
                    )}
                    {showAttendanceButton ? (
                      <courseContext.Provider value={courseStore}>
                        <CertificateOfAttendanceButton
                          courseSlug={courseSlug}
                          timestamp={dateToTimestamp(new Date())}
                        />
                      </courseContext.Provider>
                    ) : null}
                  </div>
                  <div className="course-btns-container">
                    {!isOpenSubscriberForCourse &&
                      courseCode &&
                      courseVersion &&
                      !isLS101Subscriber &&
                      !isExternalSubscriber && (
                        <React.Fragment>
                          {!isFreeTierSubscriber && (
                            <>
                              <Button
                                variant="link"
                                icon={<BullHornIcon />}
                                className="feedback-toggle"
                                onClick={() => {
                                  prepareFeedback();
                                }}
                                data-analytics-id="feedback-btn-ole-lp"
                              >
                                {t('Feedback')}
                              </Button>
                              {!isLesson && (
                                <FeatureFlag
                                  section="course"
                                  flagName="pdf_ebook_button_active"
                                >
                                  <EBookDownloader
                                    modality={modality}
                                    course={{
                                      code: courseCode,
                                      version: courseVersion,
                                    }}
                                    user={user}
                                    data-analytics-id="ebook-download-btn-ole-lp"
                                  />
                                </FeatureFlag>
                              )}

                              <FeatureFlag
                                section="course"
                                flagName="bookshelf_button_active"
                              >
                                {canAccessBookshelf && (
                                  <Flex
                                    justifyContent={{
                                      default: 'justifyContentCenter',
                                    }}
                                    alignItems={{ default: 'alignItemsCenter' }}
                                    data-analytics-id="bookshelf-btn-ole-lp"
                                  >
                                    <Button
                                      style={{ flex: 1 }}
                                      component="a"
                                      href={`${configStore.ebooksPlatformURL}ebook/${courseSlug}`}
                                      variant="secondary"
                                      target="_blank"
                                      data-analytics-id={`bookshelf-btn-ole-lp-${courseSlug}`}
                                    >
                                      {t('Access Bookshelf')}
                                    </Button>
                                  </Flex>
                                )}
                              </FeatureFlag>
                            </>
                          )}
                        </React.Fragment>
                      )}
                    {sortedVersions.length > 1 && (
                      <VersionsDropdown
                        code={courseCode}
                        currentVersion={courseVersion}
                        versions={sortedVersions}
                        isLatestVersion={isLatestVersion}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* Toolbar engs here ^ */}
              {/* TABS: */}
              {courseStore.isCourse && euuid && page && (
                <Tabs
                  id="course-tabs"
                  className="courseTabs"
                  data-analytics-id="course-tabs-tab-container"
                  activeKey={activeTab}
                  mountOnEnter
                  animation={false}
                  onSelect={onTabChange}
                >
                  {isLesson ? (
                    <Tab
                      tabClassName="toc-tab-ole-lp"
                      eventKey={1}
                      title={<TabTitle icon="list-alt" title={t('Overview')} />}
                    >
                      <LessonOverview
                        currentLanguage={uiStore.currentLanguage}
                        metadata={courseStore.courseMetadata}
                        courseCatalogEntry={courseCatalogEntry}
                        languages={courseStore.translations}
                      />
                    </Tab>
                  ) : (
                    <Tab
                      tabClassName="toc-tab-ole-lp"
                      eventKey={1}
                      title={
                        <TabTitle
                          icon="list-alt"
                          title={t('Table of Contents')}
                        />
                      }
                    >
                      {page && (
                        <TableOfContents
                          id="tab-course-toc"
                          structure={courseStructure}
                        />
                      )}
                    </Tab>
                  )}

                  <Tab
                    tabClassName="course-tab-ole-lp"
                    eventKey={2}
                    title={
                      <TabTitle
                        icon="book"
                        title={isLesson ? t('Lesson') : t('Course')}
                      />
                    }
                  >
                    <div id="tab-course-content" className="tab-course-content">
                      <courseContext.Provider value={courseStore}>
                        <BookmarkButton
                          sectionTitle={pageTitle}
                          sectionSlug={pageSlug}
                          isBooked={courseStore.isBooked}
                          courseOffering={courseStore.courseOffering}
                          checkBookmark={courseStore.checkBookmark}
                          handleBookmark={courseStore.handleBookmark}
                        />
                      </courseContext.Provider>
                      <ProgressMap
                        structure={courseStructure}
                        progressStructure={progressStructure}
                        pageSlug={courseStore.pageSlug}
                      />
                      {courseStore.isBlockedForFreeTier && (
                        <div className="free-tier-message-wrapper">
                          <p>
                            This content is unavailable during your free trial.
                            Click a section from Chapter 1 or Chapter 2 in the
                            progress bar above, to return to your free trial
                            course content.
                          </p>
                          <p>
                            Please subscribe to the Red Hat Learning
                            Subscription to receive access to all of the course
                            content.
                          </p>
                          <SubscriptionComparisonTable />
                        </div>
                      )}
                      <Navigation
                        next={next}
                        previous={previous}
                        analyticsIdPrefix="top"
                      />
                      {courseStore.isVideoAllowed && (
                        <VideoWidget
                          entryId={courseStore.pageWithVideo.entry_id}
                          partnerId={KALTURA_CONFIGS.partnerId}
                          playerId={courseStore.customVideoPlayerId}
                          isPlaylist={Boolean(
                            courseStore.pageWithVideo.is_playlist,
                          )}
                          videoWidgetTitle={courseStore.videoWidgetTitle}
                        />
                      )}
                      {courseStore.coursePageFetchInProgress ? (
                        <div className="course__skeleton">
                          <PageSkeletonLoading />
                        </div>
                      ) : (
                        <div className="course__content-wrapper">
                          <ContentArea
                            key={pageTag}
                            euuid={euuid}
                            page={page}
                            content={content}
                            language={courseStore.currentCourse?.language}
                            pageInProgress={
                              courseStore.coursePageFetchInProgress
                            }
                          />
                        </div>
                      )}
                      <Navigation
                        next={next}
                        previous={previous}
                        scroll
                        analyticsIdPrefix="bottom"
                      />
                      {!isChineseInstance && (
                        <div className="syndication-style">
                          <SyndicationComponent
                            courseCode={courseCode.toUpperCase()}
                            courseTitle={courseStore.title}
                          />
                        </div>
                      )}
                      <ContentVersionHint
                        courseSlug={courseSlug}
                        language={courseStore.currentCourse?.language}
                        buildEnv={buildEnv}
                      />
                    </div>
                  </Tab>
                  {labBlueprints.length && !labDefinition.doc_id ? (
                    <Tab
                      tabClassName="online-lab-tab-ole-lp"
                      eventKey={3}
                      title={<TabTitle icon="flash" title={t('Online Lab')} />}
                    >
                      <labControlsContext.Provider value={labControlsStore}>
                        <LabPolling
                          blueprints={labBlueprints}
                          courseSlug={courseSlug}
                        />
                      </labControlsContext.Provider>
                    </Tab>
                  ) : null}
                  {labDefinition.doc_id ? (
                    <Tab
                      tabClassName="lab-env-tab-ole-lp"
                      eventKey={8}
                      title={
                        <TabTitle icon="flash" title={t('Lab Environment')} />
                      }
                    >
                      <div id="tab-course-lab-environment">
                        <LabEnvironment
                          courseSlug={courseSlug}
                          labDefinition={labDefinition}
                          enrollmentUuid={euuid}
                        />
                      </div>
                    </Tab>
                  ) : null}
                  {/* eslint-disable-next-line camelcase */}
                  {courseStore.hasExpertExtrasInfo &&
                    !isOpenSubscriberForCourse && (
                      <Tab
                        tabClassName="expert-extras-tab-ole-lp"
                        eventKey={9}
                        title={
                          <TabTitle icon="play" title={t('Expert Extras')} />
                        }
                      >
                        <div className="expert-extras-tab-content">
                          <VideoWidget
                            className="expert-extras-player"
                            partnerId={KALTURA_CONFIGS.partnerId}
                            playerId={
                              KALTURA_CONFIGS.expertExtrasPlayer.uiConfId
                            }
                            entryId={courseStore.playListId}
                            isPlaylist
                          />
                        </div>
                      </Tab>
                    )}
                  {/* eslint-disable-next-line camelcase */}
                  {courseStore.hasLabPlusCourseInfo &&
                    courseStore.catalogStore.subscriptionHasLabPlus && (
                      <Tab
                        tabClassName="lab-plus-tab-ole-lp"
                        eventKey={11}
                        title={<TabTitle icon="flash" title={t('Lab Plus')} />}
                      >
                        {courseStore.labPlusCourses.code.map((code, index) => {
                          return renderLabPlusCourses(code, index);
                        })}
                      </Tab>
                    )}
                  <Tab
                    tabClassName="bookmark-tab-ole-lp"
                    eventKey={4}
                    title={<TabTitle icon="star" />}
                  >
                    <courseContext.Provider value={courseStore}>
                      <Favorites
                        bookmarkList={courseStore.bookmarkList}
                        onBookmark={courseStore.handleBookmark}
                      />
                    </courseContext.Provider>
                  </Tab>
                </Tabs>
              )}
            </section>
          </Col>
        </Row>
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseView),
  GenericViewError,
  handleError,
);
